import type { Language, MelwaysZone } from '@seek/melways-sites';

import type { QuickSearchLinkProps } from '../components/QuickSearchLink';

interface OtherLink {
  default: OtherLinkDetails[];
  poea?: OtherLinkDetails[];
}

export interface OtherLinkDetails {
  keywords: string;
  location: QuickSearchLinkProps['linkProps']['location'];
  rel?: string;
}

type OtherLinks = Partial<Record<Language, OtherLink>>;

export const otherLinks: Record<MelwaysZone, OtherLinks> = {
  'anz-1': {
    en: {
      default: [
        { keywords: 'All Jobs', location: { pathname: '/jobs' } },
        {
          keywords: 'Work From Home Jobs',
          location: { pathname: '/work-from-home-jobs' },
        },
        {
          keywords: 'FIFO Jobs',
          location: { pathname: '/fifo-jobs' },
        },
        { keywords: 'Ethical Jobs', location: { pathname: '/ethical-jobs' } },
        {
          keywords: 'Government Jobs',
          location: { pathname: 'government-jobs' },
        },
        { keywords: 'Web3 Jobs', location: { pathname: '/web3-jobs' } },
        {
          keywords: 'Salary Calculator',
          location: { pathname: '/career-advice/page/salary-calculator' },
        },
      ],
    },
  },
  'anz-2': {
    en: {
      default: [
        { keywords: 'All Jobs', location: { pathname: '/jobs' } },
        {
          keywords: 'No Experience Jobs',
          location: { pathname: '/no-experience-jobs' },
        },
        {
          keywords: 'Work From Home Jobs',
          location: { pathname: '/work-from-home-jobs' },
        },
        {
          keywords: 'FIFO Jobs',
          location: { pathname: '/fifo-jobs' },
        },
        { keywords: 'Farm Jobs', location: { pathname: '/farm-jobs' } },
        {
          keywords: 'Government Jobs',
          location: { pathname: '/government-jobs' },
        },
        {
          keywords: 'Visa Sponsorship Jobs',
          location: { pathname: '/visa-sponsorship-jobs' },
        },
      ],
    },
  },
  'asia-1': {
    en: {
      default: [
        { keywords: 'All Jobs', location: { pathname: '/jobs' } },
        {
          keywords: 'Government Jobs',
          location: { pathname: '/safety-officer-jobs' },
        },
        { keywords: 'Clerk Jobs', location: { pathname: '/clerk-jobs' } },
        {
          keywords: 'Math Tutor Jobs',
          location: { pathname: '/math-tutor-jobs' },
        },
        {
          keywords: '物理治療助理 Jobs',
          location: {
            pathname:
              '/%E7%89%A9%E7%90%86%E6%B2%BB%E7%99%82%E5%8A%A9%E7%90%86-jobs',
          },
        },
      ],
    },
  },
  'asia-3': {
    en: {
      default: [
        { keywords: 'All Jobs', location: { pathname: '/jobs' } },
        {
          keywords: 'Work From Home Jobs',
          location: { pathname: '/work-from-home-jobs' },
        },
        {
          keywords: 'Safety Officer Jobs',
          location: { pathname: '/safety-officer-jobs' },
        },
        { keywords: 'Admin Jobs', location: { pathname: '/admin-jobs' } },
        { keywords: 'Lawyer Jobs', location: { pathname: '/lawyer-jobs' } },
      ],
    },
    th: {
      default: [
        { keywords: 'งานทั้งหมด', location: { pathname: '/jobs' } },
        {
          keywords: 'งาน Work From Home',
          location: { pathname: '/work-from-home-jobs' },
        },
        {
          keywords: 'งาน Safety Officer',
          location: { pathname: '/safety-officer-jobs' },
        },
        { keywords: 'งาน Admin', location: { pathname: '/admin-jobs' } },
        {
          keywords: 'เครื่องคิดเลขเงินเดือน',
          location: { pathname: '/lawyer-jobs' },
        },
      ],
    },
  },
  'asia-4': {
    en: {
      default: [
        { keywords: 'All Jobs', location: { pathname: '/jobs' } },
        { keywords: 'Online Jobs', location: { pathname: '/online-jobs' } },
        {
          keywords: 'Freelance Jobs',
          location: { pathname: '/Freelance-jobs' },
        },
        { keywords: 'Driver Jobs', location: { pathname: '/driver-jobs' } },
        { keywords: 'Admin Jobs', location: { pathname: '/admin-jobs' } },
      ],
    },
    id: {
      default: [
        { keywords: 'Semua Lowongan Kerja', location: { pathname: '/jobs' } },
        { keywords: 'Kerja Online', location: { pathname: '/online-jobs' } },
        {
          keywords: 'Kerja Freelance',
          location: { pathname: '/Freelance-jobs' },
        },
        { keywords: 'Kerja Tambang', location: { pathname: '/lambang-jobs' } },
        {
          keywords: 'Lowongan Lulusan Sma',
          location: { pathname: '/lulusan-sma-jobs' },
        },
      ],
    },
  },
  'asia-5': {
    en: {
      default: [
        { keywords: 'All Jobs', location: { pathname: '/jobs' } },
        { keywords: 'Admin Jobs', location: { pathname: '/admin-jobs' } },
        {
          keywords: 'Government Jobs',
          location: { pathname: '/government-jobs' },
        },
        {
          keywords: 'Technician Jobs',
          location: { pathname: '/technician-jobs' },
        },
      ],
    },
  },
  'asia-6': {
    en: {
      default: [
        { keywords: 'All Jobs', location: { pathname: '/jobs' } },
        {
          keywords: 'Work From Home Jobs',
          location: { pathname: '/work-from-home-jobs' },
        },
        {
          keywords: 'Virtual Assistant Jobs',
          location: { pathname: '/virtual-assistant-jobs' },
        },
        {
          keywords: 'Urgent Hiring Jobs',
          location: { pathname: '/urgent-hiring-jobs' },
        },
        {
          keywords: 'Government Jobs',
          location: { pathname: '/government-jobs' },
        },
        {
          keywords: 'Work Abroad Jobs',
          location: { pathname: '/work-abroad-jobs' },
        },
        {
          keywords: 'POEA Jobs',
          location: { pathname: '/poea-jobs' },
        },
      ],
      poea: [
        {
          keywords: 'Factory Worker Japan Jobs',
          location: { pathname: '/factory-worker-jobs/in-Japan' },
        },
        {
          keywords: 'Factory Worker Taiwan Jobs',
          location: { pathname: '/factory-worker-jobs/in-Taiwan' },
        },
        {
          keywords: 'Hiring Australia Jobs',
          location: { pathname: '/hiring-jobs/in-All-Australia' },
        },
        { keywords: 'Canada Jobs', location: { pathname: '/jobs/in-Canada' } },
        { keywords: 'Qatar Jobs', location: { pathname: '/jobs/in-Qatar' } },
        {
          keywords: 'Saudi Arabia Jobs',
          location: { pathname: '/jobs/in-Saudi-Arabia' },
        },
        {
          keywords: 'No experience Canada Jobs',
          location: { pathname: '/no-experience-jobs/in-Canada' },
        },
        {
          keywords: 'No experience Japan Jobs',
          location: { pathname: '/no-experience-jobs/in-Japan' },
        },
        {
          keywords: 'No experience Macau SAR Jobs',
          location: { pathname: '/no-experience-jobs/in-Macau-SAR' },
        },
        {
          keywords: 'No experience Taiwan Jobs',
          location: { pathname: '/no-experience-jobs/in-Taiwan' },
        },
        {
          keywords: 'POEA Australia Jobs',
          location: { pathname: '/poea-jobs/in-All-Australia' },
        },
        {
          keywords: 'POEA Canada Jobs',
          location: { pathname: '/poea-jobs/in-Canada' },
        },
        {
          keywords: 'POEA Japan Jobs',
          location: { pathname: '/poea-jobs/in-Japan' },
        },
        {
          keywords: 'POEA Kuwait Jobs',
          location: { pathname: '/poea-jobs/in-Kuwait' },
        },
        {
          keywords: 'POEA Malta Jobs',
          location: { pathname: '/poea-jobs/in-Malta' },
        },
        {
          keywords: 'POEA Poland Jobs',
          location: { pathname: '/poea-jobs/in-Poland' },
        },
        {
          keywords: 'POEA Qatar Jobs',
          location: { pathname: '/poea-jobs/in-Qatar' },
        },
        {
          keywords: 'POEA Saudi Arabia Jobs',
          location: { pathname: '/poea-jobs/in-Saudi-Arabia' },
        },
        {
          keywords: 'POEA Singapore Jobs',
          location: { pathname: '/poea-jobs/in-Singapore' },
        },
        {
          keywords: 'POEA Taiwan Jobs',
          location: { pathname: '/poea-jobs/in-Taiwan' },
        },
      ],
    },
  },
  'asia-7': {
    en: {
      default: [
        { keywords: 'All Jobs', location: { pathname: '/jobs' } },
        {
          keywords: 'Work From Home Jobs',
          location: { pathname: '/work-from-home-jobs' },
        },
        {
          keywords: 'Freelance Jobs',
          location: { pathname: '/Freelance-jobs' },
        },
        { keywords: 'Intern Jobs', location: { pathname: '/intern-jobs' } },
        {
          keywords: 'Government Jobs',
          location: { pathname: '/government-jobs' },
        },
      ],
    },
  },
};
