import { useMelwaysInfo } from '@seek/melways-react';
import { useTranslations } from '@vocab/react';
import {
  Box,
  IconChevron,
  Inline,
  Text,
  TextLinkButton,
  Tiles,
} from 'braid-design-system';
import { useCallback, useState } from 'react';

import { useMelwaysZone } from 'src/utils/melwaysHelper';

import translations from '../.vocab';
import { otherLinks, type OtherLinkDetails } from '../utils/other';

import { QuickSearchLink } from './QuickSearchLink';

import * as styles from '../styles.css';

export const Other = () => {
  const { language } = useMelwaysInfo();
  const zone = useMelwaysZone();
  const { t } = useTranslations(translations);
  const links = otherLinks[zone][language]?.default;
  const poeaLinks = otherLinks[zone][language]?.poea;

  const [showPoeaLinks, setShowPoeaLinks] = useState(false);

  const slugify = (text: string) => text.replace(/\s+|\//g, '-').toLowerCase();

  const renderQuickSearchLink = useCallback(
    ({ keywords, location, rel }: OtherLinkDetails) => {
      const slugifiedKeywords = slugify(keywords);
      return (
        <QuickSearchLink
          key={slugifiedKeywords}
          linkProps={{
            'aria-label': keywords,
            'data-automation': `quick-search-${slugifiedKeywords}-link`,
            analytics: `quick-search-${slugifiedKeywords}`,
            location,
            rel,
          }}
        >
          {keywords}
        </QuickSearchLink>
      );
    },
    [],
  );

  if (!links?.length) return null;

  return (
    <Box display="flex">
      <Box className={styles.rowLeftColumn}>
        <Text size="small" component="h2">
          {t('Other')}
        </Text>
      </Box>
      <Box>
        <Inline space="small" component="ul">
          {links.map(renderQuickSearchLink)}
          {poeaLinks && poeaLinks?.length > 0 && (
            <Text size="small" tone="secondary" component="li">
              <TextLinkButton
                weight="weak"
                icon={<IconChevron direction={showPoeaLinks ? 'up' : 'down'} />}
                iconPosition="trailing"
                onClick={() => setShowPoeaLinks(!showPoeaLinks)}
                aria-expanded={showPoeaLinks}
              >
                {t('View all')}
              </TextLinkButton>
            </Text>
          )}
        </Inline>
        <Box
          display={showPoeaLinks ? 'block' : 'none'}
          paddingTop="medium"
          paddingBottom="xsmall"
        >
          <Tiles columns={3} space="small">
            {poeaLinks?.map(renderQuickSearchLink)}
          </Tiles>
        </Box>
      </Box>
    </Box>
  );
};
