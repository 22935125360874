import { useCallback } from 'react';

import { useSignedInDashboardContext } from '../../SignedInDashboardContextProvider';

import { useCareerFeedAIAssistant } from './useCareerFeedAIAssistant';

/**
 * This hook is used to reset the candidate chat context and refresh the recommendations
 */
export const useGetResetCandidateChatContext = () => {
  const { clearCandidateChatContext, reset } = useCareerFeedAIAssistant();

  const {
    recommendationsResult: { refresh },
  } = useSignedInDashboardContext();

  const resetPreferences = useCallback(() => {
    clearCandidateChatContext();
    refresh({ candidateChatContext: null });
    reset();
  }, [clearCandidateChatContext, refresh, reset]);
  return resetPreferences;
};

/**
 * This hook is used to refresh the recommendations with the latest candidate chat context from the context.
 */
export const useGetRefreshRecommendationsWithCandidateChatContext = () => {
  const { candidateChatContext, persistCandidateChatContext } =
    useCareerFeedAIAssistant();
  const {
    recommendationsResult: { refresh },
  } = useSignedInDashboardContext();

  return useCallback(async () => {
    const result = await refresh({
      candidateChatContext,
    });
    persistCandidateChatContext();
    return result;
  }, [refresh, candidateChatContext, persistCandidateChatContext]);
};
