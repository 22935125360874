import { useTranslations } from '@vocab/react';
import { Box, Text } from 'braid-design-system';
import type React from 'react';

import translations from './.vocab';
import loadingIcon from './four-pointed-loading-icon.gif';
import { useCareerFeedAIAssistant } from './hooks/useCareerFeedAIAssistant';

import * as styles from './LoadingOverlay.css';

const DotLoader = () => (
  <Box component="span" className={styles.loadingDot}>
    .
  </Box>
);

const DotsLoader = () => (
  <Box aria-hidden component="span" display="inlineBlock">
    <DotLoader />
    <DotLoader />
    <DotLoader />
  </Box>
);

const loadingPhrases = [
  "That's interesting",
  'Good to know',
  'Thank you for sharing',
  "That's very helpful",
];
const getRandomLoadingPhrase = (): string =>
  loadingPhrases[Math.floor(Math.random() * loadingPhrases.length)];

const GenAIChatLoader = () => {
  const { t } = useTranslations(translations);
  const { lastCompletedAssistantResponse } = useCareerFeedAIAssistant();

  const isLastQuestion =
    lastCompletedAssistantResponse?.collectGeneralRequirements ?? false;

  return (
    <Box width="full" height="full" className={styles.transparentBackground}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        paddingBottom="medium"
        height="full"
      >
        <Box
          component="img"
          src={loadingIcon}
          style={{ width: '47px' }}
          display="block"
          marginBottom="gutter"
        />
        <Text>
          <Box component="span" className={styles.loadingText}>
            {isLastQuestion
              ? t("Let me see if I've got this right")
              : getRandomLoadingPhrase()}
          </Box>
          <DotsLoader />
        </Text>
      </Box>
    </Box>
  );
};

export const GenAIChatLoadingOverlay = ({
  children,
  isGenerating,
}: {
  children: React.ReactNode;
  isGenerating: boolean;
}) => (
  <Box position="relative" height="full">
    {isGenerating ? (
      <>
        <Box
          position="absolute"
          width="full"
          height="full"
          right={0}
          top={0}
          bottom={0}
          zIndex="modal"
        >
          <GenAIChatLoader />
        </Box>
        {children}
      </>
    ) : (
      children
    )}
  </Box>
);
