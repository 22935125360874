import { CompanyProfileCarousel } from '@seek/branding-assets';
import { useMelwaysInfo } from '@seek/melways-react';
import type { Locale } from '@seek/melways-sites';
import { metrics } from '@seek/metrics-js';
import { Box, PageBlock } from 'braid-design-system';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  type ComponentProps,
} from 'react';

import { siteSection } from 'src/config';
import { useAppConfig } from 'src/config/appConfig';
import { useCandidateDetails } from 'src/modules/hooks/useCandidateDetailsQuery';
import type {
  ExperimentsState,
  TSeekExperiment,
} from 'src/store/experiments/types';
import { useSelector } from 'src/store/react';
import { useMelwaysZone } from 'src/utils/melwaysHelper';

interface Props {
  /**
   * Allows the parent to control the whitespace without having to
   * expose whether or not Company Profiles are available outside of
   * this component.
   */
  sectionSpace?: ComponentProps<typeof Box>['paddingTop'];
  /**
   * Whether or not the carousel should be wrapped in a `<ContentBlock />`.
   * Pass `false` if the parent will control the spacing around the carousel.
   * @default true
   */
  preserveSpace?: boolean;
  homepageLayoutRefresh?: boolean;
  onError?: () => void;
}

const experimentsMapper = (experiments: ExperimentsState) =>
  experiments
    ? Object.values(experiments).map(
        ({ name, variation, num }: TSeekExperiment) => ({
          id: name,
          variant: variation.name,
          num,
        }),
      )
    : [];

export const CPCarousel = ({
  sectionSpace,
  preserveSpace = true,
  homepageLayoutRefresh,
  onError,
}: Props) => {
  const { country } = useAppConfig();
  const { language, brand } = useMelwaysInfo();
  const zone = useMelwaysZone();
  const { id: candidateId } = useCandidateDetails();
  const [isError, setError] = useState(false);
  const isLoggedIn = useSelector((state) => Boolean(state.user.authenticated));
  const experiments = useSelector((state) =>
    experimentsMapper(state.experiments),
  );

  const payload = useMemo(
    () => ({
      experiments,
      brand,
      isLoggedIn,
      currentPage: 'home',
      siteCountry: country as Locale,
      siteLanguage: language,
      loginId: candidateId ? String(candidateId) : 'NULL',
      zone,
      siteSection,
      cardType: 'Company',
      carouselSection: 'Company Profile',
    }),
    [experiments, brand, isLoggedIn, country, language, candidateId, zone],
  );

  const onCompanyProfileWidgetError = useCallback(() => {
    setError(true);
    onError?.();
  }, [onError]);

  useEffect(() => {
    if (isError) {
      metrics.count('ui_error', [`type:company_profile_carousel`]);
    }
  }, [isError]);

  const Wrapper = preserveSpace ? PageBlock : Box;

  return !isError ? (
    <Box paddingTop={sectionSpace}>
      <Wrapper data={{ automation: 'cpCarouselContainer' }}>
        <CompanyProfileCarousel
          hideReviews={true}
          analyticsQueryParams="cid=sk-home-carousel"
          onError={onCompanyProfileWidgetError}
          payload={payload}
          featureFlags={{ homepageLayoutRefresh, clientQuery: isLoggedIn }}
        />
      </Wrapper>
    </Box>
  ) : null;
};
