import { useTranslations } from '@vocab/react';
import {
  Box,
  ButtonIcon,
  IconThumb,
  Inline,
  Notice,
  Text,
} from 'braid-design-system';

import translations from './.vocab';

import * as styleRefs from './FeedbackComponent.css';

export const FeedbackComponent = ({
  sentFeedback,
  positiveFeedbackAction,
  negativeFeedbackAction,
  selectedPositiveFeedback,
  selectedNegativeFeedback,
}: {
  sentFeedback: boolean;
  positiveFeedbackAction: () => void;
  negativeFeedbackAction: () => void;
  selectedPositiveFeedback: boolean;
  selectedNegativeFeedback: boolean;
}) => {
  const { t } = useTranslations(translations);

  if (sentFeedback) {
    return (
      <Notice tone="positive">
        <Text>{t('Thanks for your feedback')}</Text>
      </Notice>
    );
  }

  return (
    <Inline space="large" alignY="center">
      <Text>{t('Is this helpful?')}</Text>
      {/* This is a hack to make the buttons not affect the height of the parent container */}
      <Box position="relative">
        <Box
          position="absolute"
          display="flex"
          gap="large"
          className={styleRefs.offsetHeight}
        >
          <ButtonIcon
            icon={<IconThumb />}
            id="positive-button"
            label={t('Yes')}
            onClick={positiveFeedbackAction}
            variant={selectedPositiveFeedback ? 'soft' : 'transparent'}
          />

          <ButtonIcon
            icon={<IconThumb direction="down" />}
            id="negative-button"
            label={t('No')}
            onClick={negativeFeedbackAction}
            variant={selectedNegativeFeedback ? 'soft' : 'transparent'}
          />
        </Box>
      </Box>
    </Inline>
  );
};
