
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkdlbmVyYXRpbmcgc3VnZ2VzdGlvbnMiOiJHZW5lcmF0aW5nIHN1Z2dlc3Rpb25zIiwiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IjoiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IiwiVGhhdCdzIGludGVyZXN0aW5nIjoiVGhhdCdzIGludGVyZXN0aW5nIiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmsgeW91IGZvciBzaGFyaW5nIjoiVGhhbmsgeW91IGZvciBzaGFyaW5nIiwiVGhhdCdzIHZlcnkgaGVscGZ1bCI6IlRoYXQncyB2ZXJ5IGhlbHBmdWwifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkdlbmVyYXRpbmcgc3VnZ2VzdGlvbnMiOiJHZW5lcmF0aW5nIHN1Z2dlc3Rpb25zIiwiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IjoiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IiwiVGhhdCdzIGludGVyZXN0aW5nIjoiVGhhdCdzIGludGVyZXN0aW5nIiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmsgeW91IGZvciBzaGFyaW5nIjoiVGhhbmsgeW91IGZvciBzaGFyaW5nIiwiVGhhdCdzIHZlcnkgaGVscGZ1bCI6IlRoYXQncyB2ZXJ5IGhlbHBmdWwifQ%3D%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkdlbmVyYXRpbmcgc3VnZ2VzdGlvbnMiOiJHZW5lcmF0aW5nIHN1Z2dlc3Rpb25zIiwiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IjoiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IiwiVGhhdCdzIGludGVyZXN0aW5nIjoiVGhhdCdzIGludGVyZXN0aW5nIiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmsgeW91IGZvciBzaGFyaW5nIjoiVGhhbmsgeW91IGZvciBzaGFyaW5nIiwiVGhhdCdzIHZlcnkgaGVscGZ1bCI6IlRoYXQncyB2ZXJ5IGhlbHBmdWwifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkdlbmVyYXRpbmcgc3VnZ2VzdGlvbnMiOiJHZW5lcmF0aW5nIHN1Z2dlc3Rpb25zIiwiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IjoiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IiwiVGhhdCdzIGludGVyZXN0aW5nIjoiVGhhdCdzIGludGVyZXN0aW5nIiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmsgeW91IGZvciBzaGFyaW5nIjoiVGhhbmsgeW91IGZvciBzaGFyaW5nIiwiVGhhdCdzIHZlcnkgaGVscGZ1bCI6IlRoYXQncyB2ZXJ5IGhlbHBmdWwifQ%3D%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkdlbmVyYXRpbmcgc3VnZ2VzdGlvbnMiOiJHZW5lcmF0aW5nIHN1Z2dlc3Rpb25zIiwiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IjoiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IiwiVGhhdCdzIGludGVyZXN0aW5nIjoiVGhhdCdzIGludGVyZXN0aW5nIiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmsgeW91IGZvciBzaGFyaW5nIjoiVGhhbmsgeW91IGZvciBzaGFyaW5nIiwiVGhhdCdzIHZlcnkgaGVscGZ1bCI6IlRoYXQncyB2ZXJ5IGhlbHBmdWwifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkdlbmVyYXRpbmcgc3VnZ2VzdGlvbnMiOiJHZW5lcmF0aW5nIHN1Z2dlc3Rpb25zIiwiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IjoiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IiwiVGhhdCdzIGludGVyZXN0aW5nIjoiVGhhdCdzIGludGVyZXN0aW5nIiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmsgeW91IGZvciBzaGFyaW5nIjoiVGhhbmsgeW91IGZvciBzaGFyaW5nIiwiVGhhdCdzIHZlcnkgaGVscGZ1bCI6IlRoYXQncyB2ZXJ5IGhlbHBmdWwifQ%3D%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkdlbmVyYXRpbmcgc3VnZ2VzdGlvbnMiOiJHZW5lcmF0aW5nIHN1Z2dlc3Rpb25zIiwiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IjoiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IiwiVGhhdCdzIGludGVyZXN0aW5nIjoiVGhhdCdzIGludGVyZXN0aW5nIiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmsgeW91IGZvciBzaGFyaW5nIjoiVGhhbmsgeW91IGZvciBzaGFyaW5nIiwiVGhhdCdzIHZlcnkgaGVscGZ1bCI6IlRoYXQncyB2ZXJ5IGhlbHBmdWwifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkdlbmVyYXRpbmcgc3VnZ2VzdGlvbnMiOiJHZW5lcmF0aW5nIHN1Z2dlc3Rpb25zIiwiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IjoiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IiwiVGhhdCdzIGludGVyZXN0aW5nIjoiVGhhdCdzIGludGVyZXN0aW5nIiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmsgeW91IGZvciBzaGFyaW5nIjoiVGhhbmsgeW91IGZvciBzaGFyaW5nIiwiVGhhdCdzIHZlcnkgaGVscGZ1bCI6IlRoYXQncyB2ZXJ5IGhlbHBmdWwifQ%3D%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkdlbmVyYXRpbmcgc3VnZ2VzdGlvbnMiOiJHZW5lcmF0aW5nIHN1Z2dlc3Rpb25zIiwiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IjoiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IiwiVGhhdCdzIGludGVyZXN0aW5nIjoiVGhhdCdzIGludGVyZXN0aW5nIiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmsgeW91IGZvciBzaGFyaW5nIjoiVGhhbmsgeW91IGZvciBzaGFyaW5nIiwiVGhhdCdzIHZlcnkgaGVscGZ1bCI6IlRoYXQncyB2ZXJ5IGhlbHBmdWwifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkdlbmVyYXRpbmcgc3VnZ2VzdGlvbnMiOiJHZW5lcmF0aW5nIHN1Z2dlc3Rpb25zIiwiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IjoiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IiwiVGhhdCdzIGludGVyZXN0aW5nIjoiVGhhdCdzIGludGVyZXN0aW5nIiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmsgeW91IGZvciBzaGFyaW5nIjoiVGhhbmsgeW91IGZvciBzaGFyaW5nIiwiVGhhdCdzIHZlcnkgaGVscGZ1bCI6IlRoYXQncyB2ZXJ5IGhlbHBmdWwifQ%3D%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkdlbmVyYXRpbmcgc3VnZ2VzdGlvbnMiOiJHZW5lcmF0aW5nIHN1Z2dlc3Rpb25zIiwiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IjoiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IiwiVGhhdCdzIGludGVyZXN0aW5nIjoiVGhhdCdzIGludGVyZXN0aW5nIiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmsgeW91IGZvciBzaGFyaW5nIjoiVGhhbmsgeW91IGZvciBzaGFyaW5nIiwiVGhhdCdzIHZlcnkgaGVscGZ1bCI6IlRoYXQncyB2ZXJ5IGhlbHBmdWwifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkdlbmVyYXRpbmcgc3VnZ2VzdGlvbnMiOiJHZW5lcmF0aW5nIHN1Z2dlc3Rpb25zIiwiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IjoiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IiwiVGhhdCdzIGludGVyZXN0aW5nIjoiVGhhdCdzIGludGVyZXN0aW5nIiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmsgeW91IGZvciBzaGFyaW5nIjoiVGhhbmsgeW91IGZvciBzaGFyaW5nIiwiVGhhdCdzIHZlcnkgaGVscGZ1bCI6IlRoYXQncyB2ZXJ5IGhlbHBmdWwifQ%3D%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkdlbmVyYXRpbmcgc3VnZ2VzdGlvbnMiOiJHZW5lcmF0aW5nIHN1Z2dlc3Rpb25zIiwiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IjoiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IiwiVGhhdCdzIGludGVyZXN0aW5nIjoiVGhhdCdzIGludGVyZXN0aW5nIiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmsgeW91IGZvciBzaGFyaW5nIjoiVGhhbmsgeW91IGZvciBzaGFyaW5nIiwiVGhhdCdzIHZlcnkgaGVscGZ1bCI6IlRoYXQncyB2ZXJ5IGhlbHBmdWwifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkdlbmVyYXRpbmcgc3VnZ2VzdGlvbnMiOiJHZW5lcmF0aW5nIHN1Z2dlc3Rpb25zIiwiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IjoiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IiwiVGhhdCdzIGludGVyZXN0aW5nIjoiVGhhdCdzIGludGVyZXN0aW5nIiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmsgeW91IGZvciBzaGFyaW5nIjoiVGhhbmsgeW91IGZvciBzaGFyaW5nIiwiVGhhdCdzIHZlcnkgaGVscGZ1bCI6IlRoYXQncyB2ZXJ5IGhlbHBmdWwifQ%3D%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkdlbmVyYXRpbmcgc3VnZ2VzdGlvbnMiOiJHZW5lcmF0aW5nIHN1Z2dlc3Rpb25zIiwiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IjoiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IiwiVGhhdCdzIGludGVyZXN0aW5nIjoiVGhhdCdzIGludGVyZXN0aW5nIiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmsgeW91IGZvciBzaGFyaW5nIjoiVGhhbmsgeW91IGZvciBzaGFyaW5nIiwiVGhhdCdzIHZlcnkgaGVscGZ1bCI6IlRoYXQncyB2ZXJ5IGhlbHBmdWwifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkdlbmVyYXRpbmcgc3VnZ2VzdGlvbnMiOiJHZW5lcmF0aW5nIHN1Z2dlc3Rpb25zIiwiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IjoiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IiwiVGhhdCdzIGludGVyZXN0aW5nIjoiVGhhdCdzIGludGVyZXN0aW5nIiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmsgeW91IGZvciBzaGFyaW5nIjoiVGhhbmsgeW91IGZvciBzaGFyaW5nIiwiVGhhdCdzIHZlcnkgaGVscGZ1bCI6IlRoYXQncyB2ZXJ5IGhlbHBmdWwifQ%3D%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkdlbmVyYXRpbmcgc3VnZ2VzdGlvbnMiOiJHZW5lcmF0aW5nIHN1Z2dlc3Rpb25zIiwiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IjoiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IiwiVGhhdCdzIGludGVyZXN0aW5nIjoiVGhhdCdzIGludGVyZXN0aW5nIiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmsgeW91IGZvciBzaGFyaW5nIjoiVGhhbmsgeW91IGZvciBzaGFyaW5nIiwiVGhhdCdzIHZlcnkgaGVscGZ1bCI6IlRoYXQncyB2ZXJ5IGhlbHBmdWwifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkdlbmVyYXRpbmcgc3VnZ2VzdGlvbnMiOiJHZW5lcmF0aW5nIHN1Z2dlc3Rpb25zIiwiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IjoiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IiwiVGhhdCdzIGludGVyZXN0aW5nIjoiVGhhdCdzIGludGVyZXN0aW5nIiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmsgeW91IGZvciBzaGFyaW5nIjoiVGhhbmsgeW91IGZvciBzaGFyaW5nIiwiVGhhdCdzIHZlcnkgaGVscGZ1bCI6IlRoYXQncyB2ZXJ5IGhlbHBmdWwifQ%3D%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkdlbmVyYXRpbmcgc3VnZ2VzdGlvbnMiOiJHZW5lcmF0aW5nIHN1Z2dlc3Rpb25zIiwiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IjoiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IiwiVGhhdCdzIGludGVyZXN0aW5nIjoiVGhhdCdzIGludGVyZXN0aW5nIiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmsgeW91IGZvciBzaGFyaW5nIjoiVGhhbmsgeW91IGZvciBzaGFyaW5nIiwiVGhhdCdzIHZlcnkgaGVscGZ1bCI6IlRoYXQncyB2ZXJ5IGhlbHBmdWwifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkdlbmVyYXRpbmcgc3VnZ2VzdGlvbnMiOiJHZW5lcmF0aW5nIHN1Z2dlc3Rpb25zIiwiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IjoiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IiwiVGhhdCdzIGludGVyZXN0aW5nIjoiVGhhdCdzIGludGVyZXN0aW5nIiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmsgeW91IGZvciBzaGFyaW5nIjoiVGhhbmsgeW91IGZvciBzaGFyaW5nIiwiVGhhdCdzIHZlcnkgaGVscGZ1bCI6IlRoYXQncyB2ZXJ5IGhlbHBmdWwifQ%3D%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkdlbmVyYXRpbmcgc3VnZ2VzdGlvbnMiOiJHZW5lcmF0aW5nIHN1Z2dlc3Rpb25zIiwiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IjoiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IiwiVGhhdCdzIGludGVyZXN0aW5nIjoiVGhhdCdzIGludGVyZXN0aW5nIiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmsgeW91IGZvciBzaGFyaW5nIjoiVGhhbmsgeW91IGZvciBzaGFyaW5nIiwiVGhhdCdzIHZlcnkgaGVscGZ1bCI6IlRoYXQncyB2ZXJ5IGhlbHBmdWwifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkdlbmVyYXRpbmcgc3VnZ2VzdGlvbnMiOiJHZW5lcmF0aW5nIHN1Z2dlc3Rpb25zIiwiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IjoiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IiwiVGhhdCdzIGludGVyZXN0aW5nIjoiVGhhdCdzIGludGVyZXN0aW5nIiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmsgeW91IGZvciBzaGFyaW5nIjoiVGhhbmsgeW91IGZvciBzaGFyaW5nIiwiVGhhdCdzIHZlcnkgaGVscGZ1bCI6IlRoYXQncyB2ZXJ5IGhlbHBmdWwifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkdlbmVyYXRpbmcgc3VnZ2VzdGlvbnMiOiJHZW5lcmF0aW5nIHN1Z2dlc3Rpb25zIiwiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IjoiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IiwiVGhhdCdzIGludGVyZXN0aW5nIjoiVGhhdCdzIGludGVyZXN0aW5nIiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmsgeW91IGZvciBzaGFyaW5nIjoiVGhhbmsgeW91IGZvciBzaGFyaW5nIiwiVGhhdCdzIHZlcnkgaGVscGZ1bCI6IlRoYXQncyB2ZXJ5IGhlbHBmdWwifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkdlbmVyYXRpbmcgc3VnZ2VzdGlvbnMiOiJHZW5lcmF0aW5nIHN1Z2dlc3Rpb25zIiwiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IjoiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IiwiVGhhdCdzIGludGVyZXN0aW5nIjoiVGhhdCdzIGludGVyZXN0aW5nIiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmsgeW91IGZvciBzaGFyaW5nIjoiVGhhbmsgeW91IGZvciBzaGFyaW5nIiwiVGhhdCdzIHZlcnkgaGVscGZ1bCI6IlRoYXQncyB2ZXJ5IGhlbHBmdWwifQ%3D%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkdlbmVyYXRpbmcgc3VnZ2VzdGlvbnMiOiJHZW5lcmF0aW5nIHN1Z2dlc3Rpb25zIiwiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IjoiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IiwiVGhhdCdzIGludGVyZXN0aW5nIjoiVGhhdCdzIGludGVyZXN0aW5nIiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmsgeW91IGZvciBzaGFyaW5nIjoiVGhhbmsgeW91IGZvciBzaGFyaW5nIiwiVGhhdCdzIHZlcnkgaGVscGZ1bCI6IlRoYXQncyB2ZXJ5IGhlbHBmdWwifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiR2V0IGJldHRlciBqb2IgbWF0Y2hlcyIsIlJlZmluZWQgZm9yIHlvdSI6IlJlZmluZWQgZm9yIHlvdSIsIkRlZmluZSB5b3VyIHByZWZlcmVuY2VzIjoiRGVmaW5lIHlvdXIgcHJlZmVyZW5jZXMiLCJCZXRhIjoiQmV0YSIsIkdlbmVyYXRpbmcgc3VnZ2VzdGlvbnMiOiJHZW5lcmF0aW5nIHN1Z2dlc3Rpb25zIiwiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IjoiTGV0IG1lIHNlZSBpZiBJJ3ZlIGdvdCB0aGlzIHJpZ2h0IiwiVGhhdCdzIGludGVyZXN0aW5nIjoiVGhhdCdzIGludGVyZXN0aW5nIiwiR29vZCB0byBrbm93IjoiR29vZCB0byBrbm93IiwiVGhhbmsgeW91IGZvciBzaGFyaW5nIjoiVGhhbmsgeW91IGZvciBzaGFyaW5nIiwiVGhhdCdzIHZlcnkgaGVscGZ1bCI6IlRoYXQncyB2ZXJ5IGhlbHBmdWwifQ%3D%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiW8em4bq94bq94bq94bmvIMOf4bq94bq94bq94bmv4bmv4bq94bq94bq9xZkgxLXDtsO2w7bDnyBtzILEg8SDxIPhua%2FDp%2BG4qeG6veG6veG6vcWhXSIsIlJlZmluZWQgZm9yIHlvdSI6IlvFmOG6veG6vcaSw6zDrOC4geC4teC5ieG6veG6vcaMIMaSw7bDtsWZIMO9w73DtsO2x5rHml0iLCJEZWZpbmUgeW91ciBwcmVmZXJlbmNlcyI6IlvhuIrhur3hur3GksOsw6zguIHguLXguYnhur3hur0gw73DvcO2w7bHmseaxZkgxqXFmeG6veG6vcaS4bq94bq9xZnhur3hur3guIHguLXguYnDp%2BG6veG6vcWhXSIsIkJldGEiOiJb4bqe4bq94bq94bmvxIPEg10iLCJHZW5lcmF0aW5nIHN1Z2dlc3Rpb25zIjoiW8em4bq94bq94bq94LiB4Li14LmJ4bq94bq94bq9xZnEg8SDxIPhua%2FDrMOsw6zguIHguLXguYnEoyDFoceax5rHmsSjxKPhur3hur3hur3FoeG5r8Osw6zDrMO2w7bDtuC4geC4teC5icWhXSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IlvFgeG6veG6veG6veG5ryBtzILhur3hur3hur0gxaHhur3hur3hur3hur3hur3hur0gw6zDrMOsxpIgw48n4bm94bq94bq94bq9IMSjw7bDtsO24bmvIOG5r%2BG4qcOsw6zDrMWhIMWZw6zDrMOsxKPhuKnhua9dIiwiVGhhdCdzIGludGVyZXN0aW5nIjoiW%2BG5ruG4qcSDxIPEg%2BG5ryfFoSDDrMOsw6zguIHguLXguYnhua%2Fhur3hur3hur3FmeG6veG6veG6vcWh4bmvw6zDrMOs4LiB4Li14LmJxKNdIiwiR29vZCB0byBrbm93IjoiW8emw7bDtsO2w7bDtsO2xowg4bmvw7bDtsO2IMS34LiB4Li14LmJw7bDtsO2xbVdIiwiVGhhbmsgeW91IGZvciBzaGFyaW5nIjoiW%2BG5ruG4qcSDxIPEg%2BC4geC4teC5icS3IMO9w73DvcO2w7bDtseax5rHmiDGksO2w7bDtsWZIMWh4bipxIPEg8SDxZnDrMOsw6zguIHguLXguYnEo10iLCJUaGF0J3MgdmVyeSBoZWxwZnVsIjoiW%2BG5ruG4qcSDxIPEg%2BG5ryfFoSDhub3hur3hur3hur3FmcO9w73DvSDhuKnhur3hur3hur3GmsalxpLHmseax5rGml0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJHZXQgYmV0dGVyIGpvYiBtYXRjaGVzIjoiW8em4bq94bq94bq94bmvIMOf4bq94bq94bq94bmv4bmv4bq94bq94bq9xZkgxLXDtsO2w7bDnyBtzILEg8SDxIPhua%2FDp%2BG4qeG6veG6veG6vcWhXSIsIlJlZmluZWQgZm9yIHlvdSI6IlvFmOG6veG6vcaSw6zDrOC4geC4teC5ieG6veG6vcaMIMaSw7bDtsWZIMO9w73DtsO2x5rHml0iLCJEZWZpbmUgeW91ciBwcmVmZXJlbmNlcyI6IlvhuIrhur3hur3GksOsw6zguIHguLXguYnhur3hur0gw73DvcO2w7bHmseaxZkgxqXFmeG6veG6vcaS4bq94bq9xZnhur3hur3guIHguLXguYnDp%2BG6veG6vcWhXSIsIkJldGEiOiJb4bqe4bq94bq94bmvxIPEg10iLCJHZW5lcmF0aW5nIHN1Z2dlc3Rpb25zIjoiW8em4bq94bq94bq94LiB4Li14LmJ4bq94bq94bq9xZnEg8SDxIPhua%2FDrMOsw6zguIHguLXguYnEoyDFoceax5rHmsSjxKPhur3hur3hur3FoeG5r8Osw6zDrMO2w7bDtuC4geC4teC5icWhXSIsIkxldCBtZSBzZWUgaWYgSSd2ZSBnb3QgdGhpcyByaWdodCI6IlvFgeG6veG6veG6veG5ryBtzILhur3hur3hur0gxaHhur3hur3hur3hur3hur3hur0gw6zDrMOsxpIgw48n4bm94bq94bq94bq9IMSjw7bDtsO24bmvIOG5r%2BG4qcOsw6zDrMWhIMWZw6zDrMOsxKPhuKnhua9dIiwiVGhhdCdzIGludGVyZXN0aW5nIjoiW%2BG5ruG4qcSDxIPEg%2BG5ryfFoSDDrMOsw6zguIHguLXguYnhua%2Fhur3hur3hur3FmeG6veG6veG6vcWh4bmvw6zDrMOs4LiB4Li14LmJxKNdIiwiR29vZCB0byBrbm93IjoiW8emw7bDtsO2w7bDtsO2xowg4bmvw7bDtsO2IMS34LiB4Li14LmJw7bDtsO2xbVdIiwiVGhhbmsgeW91IGZvciBzaGFyaW5nIjoiW%2BG5ruG4qcSDxIPEg%2BC4geC4teC5icS3IMO9w73DvcO2w7bDtseax5rHmiDGksO2w7bDtsWZIMWh4bipxIPEg8SDxZnDrMOsw6zguIHguLXguYnEo10iLCJUaGF0J3MgdmVyeSBoZWxwZnVsIjoiW%2BG5ruG4qcSDxIPEg%2BG5ryfFoSDhub3hur3hur3hur3FmcO9w73DvSDhuKnhur3hur3hur3GmsalxpLHmseax5rGml0ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    