export const set = (key: string, value: string) => {
  if (typeof window !== 'undefined') window.sessionStorage.setItem(key, value);
};
export const get = (key: string) => {
  if (typeof window !== 'undefined') {
    return window.sessionStorage.getItem(key);
  }
};
export const has = (key: string) => {
  if (typeof window !== 'undefined')
    return window.sessionStorage.getItem(key) !== null;
};
