import { Button } from 'braid-design-system';

import type { CareerFeedAssistantQuestionSuggestion } from './CareerFeedAssistantQuestions';
import type { OptionsType } from './useSuggestions';

export const OptionsButtons = ({
  options,
  disableOnClick,
}: {
  options: OptionsType<CareerFeedAssistantQuestionSuggestion>['options'];
  disableOnClick: boolean;
}) =>
  options.map((option, index) => (
    <Button
      key={index}
      size="small"
      onClick={() => {
        if (!disableOnClick) {
          option.onToggleSelect();
        }
      }}
      variant={option.isSelected ? 'solid' : 'ghost'}
    >
      {option.data.label}
    </Button>
  ));
