import type { Locale } from '@seek/melways-sites';
import { useTranslations } from '@vocab/react';
import { Box, Button, Text, Inline, IconThumb } from 'braid-design-system';
import { useCallback, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import AnimatedHeight from 'src/components/AnimatedHeight/AnimatedHeight';
import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import { useCandidateDetails } from 'src/modules/hooks/useCandidateDetailsQuery';
import {
  set as sessionStorageSet,
  has as sessionStorageHas,
} from 'src/modules/safe-session-storage';
import { useMelwaysLocale } from 'src/utils/melwaysHelper';

import translations from './.vocab';

const getSurveyEventName = (eventLocale: Locale) => {
  switch (eventLocale) {
    case 'id-ID':
      return 'recs_intercept_survey_ID';
    case 'th-TH':
      return 'recs_intercept_survey_TH';
    case 'en-ID':
      return 'recs_intercept_survey_ID_EN';
    case 'en-TH':
      return 'recs_intercept_survey_TH_EN';
    case 'en-HK':
      return 'recs_intercept_survey_HK';
    default:
      return 'recs_intercept_survey';
  }
};

export const RecsInterceptSurvey = () => {
  const { t } = useTranslations(translations);
  const { id: seekerId } = useCandidateDetails();
  const analyticsFacade = useAnalyticsFacade();
  const HOME_RECOMMENDED_JOBS = 'Home Recommended Jobs';

  const displaySurvey =
    !sessionStorageHas('showRecsInterceptSurvey') && Boolean(seekerId);

  const [isVisible, setIsVisible] = useState(displaySurvey);
  const [isAnimationComplete, setIsAnimationComplete] =
    useState(!displaySurvey);

  const { ref: trackedComponent } = useInView({
    onChange(inView) {
      if (inView) {
        analyticsFacade.feedbackSurveyViewed({
          currentPageSection: HOME_RECOMMENDED_JOBS,
          product: HOME_RECOMMENDED_JOBS,
        });
      }
    },
    delay: 500,
    threshold: 0.5,
    triggerOnce: true,
  });

  useEffect(() => {
    window.hj =
      window.hj ||
      function () {
        // eslint-disable-next-line prefer-rest-params
        (hj.q = hj.q || []).push(arguments);
      };
  }, []);

  const locale = useMelwaysLocale();

  const handleClickEvent = useCallback(() => {
    if (seekerId) {
      window.hj('identify', seekerId.toString(), { candidate_id: seekerId });
      window.hj('event', getSurveyEventName(locale));
    }
    sessionStorageSet('showRecsInterceptSurvey', 'done');
    setIsVisible(false);
    setTimeout(() => setIsAnimationComplete(true), 480);
  }, [seekerId, locale]);

  const handleClickYes = useCallback(() => {
    analyticsFacade.feedbackSurveyPressed({
      currentPageSection: HOME_RECOMMENDED_JOBS,
      product: HOME_RECOMMENDED_JOBS,
      elementText: 'Positive',
      elementAction: 'Send Feedback',
    });
    handleClickEvent();
  }, [analyticsFacade, handleClickEvent]);

  const handleClickNo = useCallback(() => {
    analyticsFacade.feedbackSurveyPressed({
      currentPageSection: HOME_RECOMMENDED_JOBS,
      product: HOME_RECOMMENDED_JOBS,
      elementText: 'Negative',
      elementAction: 'Send Feedback',
    });
    handleClickEvent();
  }, [analyticsFacade, handleClickEvent]);

  return (
    !isAnimationComplete && (
      <AnimatedHeight changeKeys={[isVisible]}>
        {isVisible && (
          <Box
            background="neutralSoft"
            borderRadius="large"
            padding="medium"
            ref={trackedComponent}
          >
            <Inline
              alignY="center"
              align={{ mobile: 'center', tablet: 'left' }}
              space="small"
              collapseBelow="tablet"
            >
              <Text size="small" weight="strong" align="center">
                {t('Are these recommendations right for you?')}
              </Text>
              <Inline space="small">
                <Button
                  size="small"
                  icon={<IconThumb />}
                  onClick={handleClickYes}
                >
                  {t('Yes')}
                </Button>
                <Button
                  size="small"
                  icon={<IconThumb direction="down" />}
                  onClick={handleClickNo}
                >
                  {t('No')}
                </Button>
              </Inline>
            </Inline>
          </Box>
        )}
      </AnimatedHeight>
    )
  );
};
