import { Box, Text } from 'braid-design-system';
import type { ComponentProps } from 'react';

import * as styleRefs from './TextSection.css';

export const TextSection = ({
  children,
  size,
}: {
  children: ComponentProps<typeof Text>['children'];
  size?: ComponentProps<typeof Text>['size'];
}) => (
  <Text size={size}>
    <Box className={styleRefs.lineBreakPreLine} component="span">
      {children}
    </Box>
  </Text>
);
