import { useMelwaysLink } from '@seek/melways-react';
import { useTranslations } from '@vocab/react';
import {
  Box,
  Stack,
  TextLink,
  Text,
  TextLinkButton,
  IconInfo,
  TooltipRenderer,
  Inline,
  Heading,
} from 'braid-design-system';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import AnimatedHeight from 'src/components/AnimatedHeight/AnimatedHeight';
import {
  isBrowserAnalyticsFacade,
  useAnalyticsFacade,
} from 'src/modules/AnalyticsFacade';
import useMeasureTimeFirstMountFromResponseEnd from 'src/modules/hooks/useMeasureTimeFirstMountFromResponseEnd';
import { useIsHomepageGenAIChatEnabled } from 'src/store/featureFlags/hooks';
import { saveSolReferencesForLoggedInHomeRecsAction } from 'src/store/results';

import { useGetResetCandidateChatContext } from '../CareerFeedAIAssistant/hooks/candidateChatContextHelpers';
import { useCareerFeedAIAssistant } from '../CareerFeedAIAssistant/hooks/useCareerFeedAIAssistant';
import { RecsInterceptSurvey } from '../RecsInterceptSurvey/RecsInterceptSurvey';
import { CardsStack } from '../SharedComponent/CardsStack/CardsStack';
import { ErrorState } from '../SharedComponent/ErrorState/ErrorState';
import { MessageWrapper } from '../SharedComponent/MessageWrapper/MessageWrapper';
import { useSignedInDashboardContext } from '../SignedInDashboardContextProvider';
import {
  RECOMMENDATIONS_LIMIT,
  type RecommendedJobs,
} from '../hooks/useHomeRecommendations';

import translations from './.vocab';
import { LoadingRecommendations } from './LoadingRecommendations';
import { RecommendedJobCard } from './RecommendedJobCard';

const LOADING_SKELETON_COUNT = RECOMMENDATIONS_LIMIT;
export const JOBS_BEFORE_SURVEY_LIMIT = 20;

const NoRecommendations = () => {
  const profileLink = useMelwaysLink({
    path: '/profile/me',
  });
  const analyticsFacade = useAnalyticsFacade();

  const profileLinkOnClick = useCallback(() => {
    analyticsFacade.linkClicked({
      href: profileLink,
      linkName: 'dashboard-recommended-jobs-profile',
    });
  }, [analyticsFacade, profileLink]);

  const { t } = useTranslations(translations);

  return (
    <MessageWrapper>
      {t('Update your profile or start searching', {
        Link: (children) => (
          <TextLink
            weight="weak"
            target="_blank"
            href={profileLink}
            onClick={profileLinkOnClick}
          >
            {children}
          </TextLink>
        ),
      })}
    </MessageWrapper>
  );
};

const useTrackingRecommendedJobs = (
  recommendedJobs: RecommendedJobs | null | undefined,
) => {
  const [isViewedEventSent, setIsViewedEventSent] = useState(false);
  const analyticsFacade = useAnalyticsFacade();
  const dispatch = useDispatch();

  useEffect(() => {
    if (recommendedJobs && !isViewedEventSent) {
      if (isBrowserAnalyticsFacade(analyticsFacade)) {
        analyticsFacade.recommendedJobsImpression({
          solMetadata: recommendedJobs.solMetadata,
        });
      }
      setIsViewedEventSent(true);
    }
  }, [
    analyticsFacade,
    isViewedEventSent,
    recommendedJobs,
    setIsViewedEventSent,
  ]);

  useEffect(() => {
    const globalJobs = recommendedJobs?.globalJobs ?? [];
    if (globalJobs.length) {
      dispatch(saveSolReferencesForLoggedInHomeRecsAction(globalJobs));
    }
  }, [dispatch, recommendedJobs]);
};

const RecommendationsContent = () => {
  const isHomepageGenAIChatEnabled = useIsHomepageGenAIChatEnabled();
  const {
    persistedCandidateChatContext,
    tracking: { trackGenAIChatResetPressed },
  } = useCareerFeedAIAssistant();

  const isRenderingGenAIRecs = Boolean(
    isHomepageGenAIChatEnabled && persistedCandidateChatContext,
  );

  const {
    recommendationsResult: { jobs, loading, error },
  } = useSignedInDashboardContext();

  const resetPreferences = useGetResetCandidateChatContext();

  const onReset = useCallback(() => {
    trackGenAIChatResetPressed('home recs');
    resetPreferences();
  }, [resetPreferences, trackGenAIChatResetPressed]);

  const { t } = useTranslations(translations);

  if (loading) {
    return (
      <LoadingRecommendations
        length={LOADING_SKELETON_COUNT}
        keyPrefix="recs"
      />
    );
  }

  if (error) {
    return <ErrorState section="recommendedJobs" />;
  }

  if (!jobs.length) {
    if (isRenderingGenAIRecs) {
      return (
        <Box paddingY="large">
          <Stack space="medium">
            <Stack space="small">
              <Text size="large">
                {t(
                  'We couldn’t find any job adverts matching your refined criteria.',
                )}
              </Text>
              <Text>
                {t(
                  'Sometimes the filters can get a bit too specific. Try broadening your preferences or checking back later as new jobs are posted.',
                )}
              </Text>
            </Stack>
            <Text weight="medium">
              <TextLinkButton onClick={onReset}>{t('Reset')}</TextLinkButton>
            </Text>
          </Stack>
        </Box>
      );
    }
    return <NoRecommendations />;
  }

  const recommendationsList = jobs.map((job, index) => (
    <RecommendedJobCard
      key={`recommendedJob_${job.id}`}
      testAutomation={{
        jobCardLink: `recommendedJobLink_${job.id}`,
      }}
      jobInfo={job}
      position={index + 1}
    />
  ));

  if (jobs.length >= JOBS_BEFORE_SURVEY_LIMIT) {
    recommendationsList.splice(
      JOBS_BEFORE_SURVEY_LIMIT,
      0,
      <RecsInterceptSurvey key="recsInterceptSurvey" />,
    );
  }

  return (
    <CardsStack data={{ automation: 'recommendedJobs' }}>
      {recommendationsList}
    </CardsStack>
  );
};

export const RECOMMENDED_JOBS_SPACE = 'small';

export const Recommendations = () => {
  const { t } = useTranslations(translations);

  const {
    recommendationsResult: { rawRecommendedJobs, loading },
  } = useSignedInDashboardContext();

  useMeasureTimeFirstMountFromResponseEnd('HomePageRecsDisplayed', !loading);
  useTrackingRecommendedJobs(rawRecommendedJobs);

  return (
    <Stack space={RECOMMENDED_JOBS_SPACE}>
      <Inline space="xxsmall" alignY="center">
        <Heading level="3">{t('Recommended jobs')}</Heading>
        <TooltipRenderer
          id=""
          tooltip={
            <Text align="center" size="small">
              {t('Based on your profile, job views and applications.')}
            </Text>
          }
        >
          {({ triggerProps }) => (
            <Box aria-label="Info" {...triggerProps}>
              <IconInfo size="xsmall" />
            </Box>
          )}
        </TooltipRenderer>
      </Inline>

      <AnimatedHeight changeKeys={[loading, rawRecommendedJobs]}>
        <RecommendationsContent />
      </AnimatedHeight>
    </Stack>
  );
};
