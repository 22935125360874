import "src/components/HomePage/HomePageBanner/GenericAsiaBanner/GenericAsiaBanner.css.ts.vanilla.css!=!../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.18_babel-plugin-macros@3.1.0_webpack@5.98.0/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/HomePage/HomePageBanner/GenericAsiaBanner/GenericAsiaBanner.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA+2SwXKCMBCG7zxFjnKIg4AoeOmbdGISIRUSJkShdnz3Dqk4ZgVp771B/m9h99ss31cq6Jr8wtCXh9Ch5B1mQnNqhJIZOhO9wHhg1v7Ou3rL4ZXbkpowJmSOjaohn/i7B2CvjFEVZDYOU/KDgcTWIbTIiyckdRs72MYKPoYS+7VKSDye723eCmYKGFEb7Qk95lqdJMOiIjmH1ApSjbjwDFF15hokmtecmAxJdXsEea0aMbaH0B03t+M+1FFVKg2LAreomFte5PLC8m0hDMdNTSjv2241qR3qw1IPN6YfvDxVcucckwxFQVB37inNUJjE8HRzbyyicVTpwHfzZCbfzuQpyMHcRyh3WKc0RMiJheHPDFEuTb/xq/dWcSYIaqjmXCIiGVpUpMO3O7buRfj2L0DjpMgplVMyX+mMfEiMC51X+krqD3GdkCHkIGMT/8u4y0jT8K8y5j+6Cn9737Rqx/ym0ajfKE7m/Sazfp8J6PeZgH6Twe83nxnID90GAAA=\"}!../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.18_babel-plugin-macros@3.1.0_webpack@5.98.0/node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var background = '_1o0xsgzg';
export var backgroundColour = 'var(--_1o0xsgz0)';
export var backgroundImage = 'var(--_1o0xsgz1)';
export var backgroundPosition = 'var(--_1o0xsgz2)';
export var centerImage = '_1o0xsgzk';
export var containerDirection = 'var(--_1o0xsgz5)';
export var containerLayout = '_1o0xsgzd';
export var contentGutter = '_1o0xsgze';
export var contentPaddingBottom = 'var(--_1o0xsgz7)';
export var contentPaddingLeft = 'var(--_1o0xsgz8)';
export var contentPaddingRight = 'var(--_1o0xsgz9)';
export var contentPaddingTop = 'var(--_1o0xsgz6)';
export var image = '_1o0xsgzf';
export var imageHeightVar = 'var(--_1o0xsgza)';
export var imageMinHeightVar = 'var(--_1o0xsgzb)';
export var imageWidthVar = 'var(--_1o0xsgzc)';
export var nowrap = '_1o0xsgzi';
export var responsive = '_1o0xsgzj';
export var title = '_1o0xsgzh';
export var titleFontSize = 'var(--_1o0xsgz4)';
export var titleTopPadding = 'var(--_1o0xsgz3)';