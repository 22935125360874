
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJcIkhlbGxvXCIgdG8gYSBiZXR0ZXIgY2FyZWVyIGFuZCBzYWxhcnkiOiJcIkhlbGxvXCIgdG8gYSBiZXR0ZXIgY2FyZWVyIGFuZCBzYWxhcnkiLCJHZXQgdGlwcyBhbmQgdG9vbHMiOiJHZXQgdGlwcyBhbmQgdG9vbHMiLCJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIjoiXCJIZWxsb1wiIHRvIGJldHRlciB0YWxlbnQgbWF0Y2hlcyIsIkhpcmUgZm9yIEZSRUUiOiJIaXJlIGZvciBGUkVFIiwiQWxsIHRoZSBqdWljeSBzYWxhcnkgbmV3cyBpbiB5b3VyIGluZHVzdHJ5IjoiQWxsIHRoZSBqdWljeSBzYWxhcnkgbmV3cyBpbiB5b3VyIGluZHVzdHJ5IiwiR28gdG8gQ29tbXVuaXR5IjoiR28gdG8gQ29tbXVuaXR5In0%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJcIkhlbGxvXCIgdG8gYSBiZXR0ZXIgY2FyZWVyIGFuZCBzYWxhcnkiOiJcIkhlbGxvXCIgdG8gYSBiZXR0ZXIgY2FyZWVyIGFuZCBzYWxhcnkiLCJHZXQgdGlwcyBhbmQgdG9vbHMiOiJHZXQgdGlwcyBhbmQgdG9vbHMiLCJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIjoiXCJIZWxsb1wiIHRvIGJldHRlciB0YWxlbnQgbWF0Y2hlcyIsIkhpcmUgZm9yIEZSRUUiOiJIaXJlIGZvciBGUkVFIiwiQWxsIHRoZSBqdWljeSBzYWxhcnkgbmV3cyBpbiB5b3VyIGluZHVzdHJ5IjoiQWxsIHRoZSBqdWljeSBzYWxhcnkgbmV3cyBpbiB5b3VyIGluZHVzdHJ5IiwiR28gdG8gQ29tbXVuaXR5IjoiR28gdG8gQ29tbXVuaXR5In0%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJcIkhlbGxvXCIgdG8gYSBiZXR0ZXIgY2FyZWVyIGFuZCBzYWxhcnkiOiJcIkhlbGxvXCIgdG8gYSBiZXR0ZXIgY2FyZWVyIGFuZCBzYWxhcnkiLCJHZXQgdGlwcyBhbmQgdG9vbHMiOiJHZXQgdGlwcyBhbmQgdG9vbHMiLCJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIjoiXCJIZWxsb1wiIHRvIGJldHRlciB0YWxlbnQgbWF0Y2hlcyIsIkhpcmUgZm9yIEZSRUUiOiJIaXJlIGZvciBGUkVFIiwiQWxsIHRoZSBqdWljeSBzYWxhcnkgbmV3cyBpbiB5b3VyIGluZHVzdHJ5IjoiQWxsIHRoZSBqdWljeSBzYWxhcnkgbmV3cyBpbiB5b3VyIGluZHVzdHJ5IiwiR28gdG8gQ29tbXVuaXR5IjoiR28gdG8gQ29tbXVuaXR5In0%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJcIkhlbGxvXCIgdG8gYSBiZXR0ZXIgY2FyZWVyIGFuZCBzYWxhcnkiOiJcIkhlbGxvXCIgdG8gYSBiZXR0ZXIgY2FyZWVyIGFuZCBzYWxhcnkiLCJHZXQgdGlwcyBhbmQgdG9vbHMiOiJHZXQgdGlwcyBhbmQgdG9vbHMiLCJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIjoiXCJIZWxsb1wiIHRvIGJldHRlciB0YWxlbnQgbWF0Y2hlcyIsIkhpcmUgZm9yIEZSRUUiOiJIaXJlIGZvciBGUkVFIiwiQWxsIHRoZSBqdWljeSBzYWxhcnkgbmV3cyBpbiB5b3VyIGluZHVzdHJ5IjoiQWxsIHRoZSBqdWljeSBzYWxhcnkgbmV3cyBpbiB5b3VyIGluZHVzdHJ5IiwiR28gdG8gQ29tbXVuaXR5IjoiR28gdG8gQ29tbXVuaXR5In0%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJcIkhlbGxvXCIgdG8gYSBiZXR0ZXIgY2FyZWVyIGFuZCBzYWxhcnkiOiJcIkhlbGxvXCIgdG8gYSBiZXR0ZXIgY2FyZWVyIGFuZCBzYWxhcnkiLCJHZXQgdGlwcyBhbmQgdG9vbHMiOiJHZXQgdGlwcyBhbmQgdG9vbHMiLCJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIjoiXCJIZWxsb1wiIHRvIGJldHRlciB0YWxlbnQgbWF0Y2hlcyIsIkhpcmUgZm9yIEZSRUUiOiJIaXJlIGZvciBGUkVFIiwiQWxsIHRoZSBqdWljeSBzYWxhcnkgbmV3cyBpbiB5b3VyIGluZHVzdHJ5IjoiQWxsIHRoZSBqdWljeSBzYWxhcnkgbmV3cyBpbiB5b3VyIGluZHVzdHJ5IiwiR28gdG8gQ29tbXVuaXR5IjoiR28gdG8gQ29tbXVuaXR5In0%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJcIkhlbGxvXCIgdG8gYSBiZXR0ZXIgY2FyZWVyIGFuZCBzYWxhcnkiOiJcIkhlbGxvXCIgdG8gYSBiZXR0ZXIgY2FyZWVyIGFuZCBzYWxhcnkiLCJHZXQgdGlwcyBhbmQgdG9vbHMiOiJHZXQgdGlwcyBhbmQgdG9vbHMiLCJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIjoiXCJIZWxsb1wiIHRvIGJldHRlciB0YWxlbnQgbWF0Y2hlcyIsIkhpcmUgZm9yIEZSRUUiOiJIaXJlIGZvciBGUkVFIiwiQWxsIHRoZSBqdWljeSBzYWxhcnkgbmV3cyBpbiB5b3VyIGluZHVzdHJ5IjoiQWxsIHRoZSBqdWljeSBzYWxhcnkgbmV3cyBpbiB5b3VyIGluZHVzdHJ5IiwiR28gdG8gQ29tbXVuaXR5IjoiR28gdG8gQ29tbXVuaXR5In0%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJcIkhlbGxvXCIgdG8gYSBiZXR0ZXIgY2FyZWVyIGFuZCBzYWxhcnkiOiJcIkhlbGxvXCIgdG8gYSBiZXR0ZXIgY2FyZWVyIGFuZCBzYWxhcnkiLCJHZXQgdGlwcyBhbmQgdG9vbHMiOiJHZXQgdGlwcyBhbmQgdG9vbHMiLCJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIjoiXCJIZWxsb1wiIHRvIGJldHRlciB0YWxlbnQgbWF0Y2hlcyIsIkhpcmUgZm9yIEZSRUUiOiJIaXJlIGZvciBGUkVFIiwiQWxsIHRoZSBqdWljeSBzYWxhcnkgbmV3cyBpbiB5b3VyIGluZHVzdHJ5IjoiQWxsIHRoZSBqdWljeSBzYWxhcnkgbmV3cyBpbiB5b3VyIGluZHVzdHJ5IiwiR28gdG8gQ29tbXVuaXR5IjoiR28gdG8gQ29tbXVuaXR5In0%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJcIkhlbGxvXCIgdG8gYSBiZXR0ZXIgY2FyZWVyIGFuZCBzYWxhcnkiOiJcIkhlbGxvXCIgdG8gYSBiZXR0ZXIgY2FyZWVyIGFuZCBzYWxhcnkiLCJHZXQgdGlwcyBhbmQgdG9vbHMiOiJHZXQgdGlwcyBhbmQgdG9vbHMiLCJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIjoiXCJIZWxsb1wiIHRvIGJldHRlciB0YWxlbnQgbWF0Y2hlcyIsIkhpcmUgZm9yIEZSRUUiOiJIaXJlIGZvciBGUkVFIiwiQWxsIHRoZSBqdWljeSBzYWxhcnkgbmV3cyBpbiB5b3VyIGluZHVzdHJ5IjoiQWxsIHRoZSBqdWljeSBzYWxhcnkgbmV3cyBpbiB5b3VyIGluZHVzdHJ5IiwiR28gdG8gQ29tbXVuaXR5IjoiR28gdG8gQ29tbXVuaXR5In0%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJcIkhlbGxvXCIgdG8gYSBiZXR0ZXIgY2FyZWVyIGFuZCBzYWxhcnkiOiJcIkhlbGxvXCIgdG8gYSBiZXR0ZXIgY2FyZWVyIGFuZCBzYWxhcnkiLCJHZXQgdGlwcyBhbmQgdG9vbHMiOiJHZXQgdGlwcyBhbmQgdG9vbHMiLCJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIjoiXCJIZWxsb1wiIHRvIGJldHRlciB0YWxlbnQgbWF0Y2hlcyIsIkhpcmUgZm9yIEZSRUUiOiJIaXJlIGZvciBGUkVFIiwiQWxsIHRoZSBqdWljeSBzYWxhcnkgbmV3cyBpbiB5b3VyIGluZHVzdHJ5IjoiQWxsIHRoZSBqdWljeSBzYWxhcnkgbmV3cyBpbiB5b3VyIGluZHVzdHJ5IiwiR28gdG8gQ29tbXVuaXR5IjoiR28gdG8gQ29tbXVuaXR5In0%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJcIkhlbGxvXCIgdG8gYSBiZXR0ZXIgY2FyZWVyIGFuZCBzYWxhcnkiOiJcIkhlbGxvXCIgdG8gYSBiZXR0ZXIgY2FyZWVyIGFuZCBzYWxhcnkiLCJHZXQgdGlwcyBhbmQgdG9vbHMiOiJHZXQgdGlwcyBhbmQgdG9vbHMiLCJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIjoiXCJIZWxsb1wiIHRvIGJldHRlciB0YWxlbnQgbWF0Y2hlcyIsIkhpcmUgZm9yIEZSRUUiOiJIaXJlIGZvciBGUkVFIiwiQWxsIHRoZSBqdWljeSBzYWxhcnkgbmV3cyBpbiB5b3VyIGluZHVzdHJ5IjoiQWxsIHRoZSBqdWljeSBzYWxhcnkgbmV3cyBpbiB5b3VyIGluZHVzdHJ5IiwiR28gdG8gQ29tbXVuaXR5IjoiR28gdG8gQ29tbXVuaXR5In0%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJcIkhlbGxvXCIgdG8gYSBiZXR0ZXIgY2FyZWVyIGFuZCBzYWxhcnkiOiJcIkhlbGxvXCIgdG8gYSBiZXR0ZXIgY2FyZWVyIGFuZCBzYWxhcnkiLCJHZXQgdGlwcyBhbmQgdG9vbHMiOiJHZXQgdGlwcyBhbmQgdG9vbHMiLCJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIjoiXCJIZWxsb1wiIHRvIGJldHRlciB0YWxlbnQgbWF0Y2hlcyIsIkhpcmUgZm9yIEZSRUUiOiJIaXJlIGZvciBGUkVFIiwiQWxsIHRoZSBqdWljeSBzYWxhcnkgbmV3cyBpbiB5b3VyIGluZHVzdHJ5IjoiQWxsIHRoZSBqdWljeSBzYWxhcnkgbmV3cyBpbiB5b3VyIGluZHVzdHJ5IiwiR28gdG8gQ29tbXVuaXR5IjoiR28gdG8gQ29tbXVuaXR5In0%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJcIkhlbGxvXCIgdG8gYSBiZXR0ZXIgY2FyZWVyIGFuZCBzYWxhcnkiOiJcIkhlbGxvXCIgdG8gYSBiZXR0ZXIgY2FyZWVyIGFuZCBzYWxhcnkiLCJHZXQgdGlwcyBhbmQgdG9vbHMiOiJHZXQgdGlwcyBhbmQgdG9vbHMiLCJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIjoiXCJIZWxsb1wiIHRvIGJldHRlciB0YWxlbnQgbWF0Y2hlcyIsIkhpcmUgZm9yIEZSRUUiOiJIaXJlIGZvciBGUkVFIiwiQWxsIHRoZSBqdWljeSBzYWxhcnkgbmV3cyBpbiB5b3VyIGluZHVzdHJ5IjoiQWxsIHRoZSBqdWljeSBzYWxhcnkgbmV3cyBpbiB5b3VyIGluZHVzdHJ5IiwiR28gdG8gQ29tbXVuaXR5IjoiR28gdG8gQ29tbXVuaXR5In0%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJcIkhlbGxvXCIgdG8gYSBiZXR0ZXIgY2FyZWVyIGFuZCBzYWxhcnkiOiJcIkhlbGxvXCIgdG8gYSBiZXR0ZXIgY2FyZWVyIGFuZCBzYWxhcnkiLCJHZXQgdGlwcyBhbmQgdG9vbHMiOiJHZXQgdGlwcyBhbmQgdG9vbHMiLCJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIjoiXCJIZWxsb1wiIHRvIGJldHRlciB0YWxlbnQgbWF0Y2hlcyIsIkhpcmUgZm9yIEZSRUUiOiJIaXJlIE5vdyIsIkFsbCB0aGUganVpY3kgc2FsYXJ5IG5ld3MgaW4geW91ciBpbmR1c3RyeSI6IkFsbCB0aGUganVpY3kgc2FsYXJ5IG5ld3MgaW4geW91ciBpbmR1c3RyeSIsIkdvIHRvIENvbW11bml0eSI6IkdvIHRvIENvbW11bml0eSJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJcIkhlbGxvXCIgdG8gYSBiZXR0ZXIgY2FyZWVyIGFuZCBzYWxhcnkiOiJcIkhlbGxvXCIgdG8gYSBiZXR0ZXIgY2FyZWVyIGFuZCBzYWxhcnkiLCJHZXQgdGlwcyBhbmQgdG9vbHMiOiJHZXQgdGlwcyBhbmQgdG9vbHMiLCJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIjoiXCJIZWxsb1wiIHRvIGJldHRlciB0YWxlbnQgbWF0Y2hlcyIsIkhpcmUgZm9yIEZSRUUiOiJIaXJlIE5vdyIsIkFsbCB0aGUganVpY3kgc2FsYXJ5IG5ld3MgaW4geW91ciBpbmR1c3RyeSI6IkFsbCB0aGUganVpY3kgc2FsYXJ5IG5ld3MgaW4geW91ciBpbmR1c3RyeSIsIkdvIHRvIENvbW11bml0eSI6IkdvIHRvIENvbW11bml0eSJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJcIkhlbGxvXCIgdG8gYSBiZXR0ZXIgY2FyZWVyIGFuZCBzYWxhcnkiOiJcIkhlbGxvXCIgdG8gYSBiZXR0ZXIgY2FyZWVyIGFuZCBzYWxhcnkiLCJHZXQgdGlwcyBhbmQgdG9vbHMiOiJHZXQgdGlwcyBhbmQgdG9vbHMiLCJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIjoiXCJIZWxsb1wiIHRvIGJldHRlciB0YWxlbnQgbWF0Y2hlcyIsIkhpcmUgZm9yIEZSRUUiOiJIaXJlIGZvciBGUkVFIiwiQWxsIHRoZSBqdWljeSBzYWxhcnkgbmV3cyBpbiB5b3VyIGluZHVzdHJ5IjoiQWxsIHRoZSBqdWljeSBzYWxhcnkgbmV3cyBpbiB5b3VyIGluZHVzdHJ5IiwiR28gdG8gQ29tbXVuaXR5IjoiR28gdG8gQ29tbXVuaXR5In0%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJcIkhlbGxvXCIgdG8gYSBiZXR0ZXIgY2FyZWVyIGFuZCBzYWxhcnkiOiJcIkhlbGxvXCIgdG8gYSBiZXR0ZXIgY2FyZWVyIGFuZCBzYWxhcnkiLCJHZXQgdGlwcyBhbmQgdG9vbHMiOiJHZXQgdGlwcyBhbmQgdG9vbHMiLCJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIjoiXCJIZWxsb1wiIHRvIGJldHRlciB0YWxlbnQgbWF0Y2hlcyIsIkhpcmUgZm9yIEZSRUUiOiJIaXJlIGZvciBGUkVFIiwiQWxsIHRoZSBqdWljeSBzYWxhcnkgbmV3cyBpbiB5b3VyIGluZHVzdHJ5IjoiQWxsIHRoZSBqdWljeSBzYWxhcnkgbmV3cyBpbiB5b3VyIGluZHVzdHJ5IiwiR28gdG8gQ29tbXVuaXR5IjoiR28gdG8gQ29tbXVuaXR5In0%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJcIkhlbGxvXCIgdG8gYSBiZXR0ZXIgY2FyZWVyIGFuZCBzYWxhcnkiOiJcIkhlbGxvXCIgdG8gYSBiZXR0ZXIgY2FyZWVyIGFuZCBzYWxhcnkiLCJHZXQgdGlwcyBhbmQgdG9vbHMiOiJHZXQgdGlwcyBhbmQgdG9vbHMiLCJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIjoiXCJIZWxsb1wiIHRvIGJldHRlciB0YWxlbnQgbWF0Y2hlcyIsIkhpcmUgZm9yIEZSRUUiOiJIaXJlIGZvciBGUkVFIiwiQWxsIHRoZSBqdWljeSBzYWxhcnkgbmV3cyBpbiB5b3VyIGluZHVzdHJ5IjoiQWxsIHRoZSBqdWljeSBzYWxhcnkgbmV3cyBpbiB5b3VyIGluZHVzdHJ5IiwiR28gdG8gQ29tbXVuaXR5IjoiR28gdG8gQ29tbXVuaXR5In0%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJcIkhlbGxvXCIgdG8gYSBiZXR0ZXIgY2FyZWVyIGFuZCBzYWxhcnkiOiJcIkhlbGxvXCIgdG8gYSBiZXR0ZXIgY2FyZWVyIGFuZCBzYWxhcnkiLCJHZXQgdGlwcyBhbmQgdG9vbHMiOiJHZXQgdGlwcyBhbmQgdG9vbHMiLCJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIjoiXCJIZWxsb1wiIHRvIGJldHRlciB0YWxlbnQgbWF0Y2hlcyIsIkhpcmUgZm9yIEZSRUUiOiJIaXJlIGZvciBGUkVFIiwiQWxsIHRoZSBqdWljeSBzYWxhcnkgbmV3cyBpbiB5b3VyIGluZHVzdHJ5IjoiQWxsIHRoZSBqdWljeSBzYWxhcnkgbmV3cyBpbiB5b3VyIGluZHVzdHJ5IiwiR28gdG8gQ29tbXVuaXR5IjoiR28gdG8gQ29tbXVuaXR5In0%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJcIkhlbGxvXCIgdG8gYSBiZXR0ZXIgY2FyZWVyIGFuZCBzYWxhcnkiOiJcIkhlbGxvXCIga2FyaXIgZGFuIGdhamkgbGViaWggYmFpayIsIkdldCB0aXBzIGFuZCB0b29scyI6IkRhcGV0aW4gdGlwcyAmIGZpdHVybnlhIiwiXCJIZWxsb1wiIHRvIGJldHRlciB0YWxlbnQgbWF0Y2hlcyI6IlwiSGVsbG9cIiBQZW5jb2Nva2FuIGthbmRpZGF0IGxlYmloIGJhaWsiLCJIaXJlIGZvciBGUkVFIjoiTXVsYWkgc2VrYXJhbmcsIEdSQVRJUyEiLCJBbGwgdGhlIGp1aWN5IHNhbGFyeSBuZXdzIGluIHlvdXIgaW5kdXN0cnkiOiJVcGRhdGUgc2VwdXRhciBpbmZvcm1hc2kgZ2FqaSBkaSBpbmR1c3RyaSBrYW11IiwiR28gdG8gQ29tbXVuaXR5IjoiR2FidW5nIHNla2FyYW5nIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJcIkhlbGxvXCIgdG8gYSBiZXR0ZXIgY2FyZWVyIGFuZCBzYWxhcnkiOiJcIkhlbGxvXCIga2FyaXIgZGFuIGdhamkgbGViaWggYmFpayIsIkdldCB0aXBzIGFuZCB0b29scyI6IkRhcGV0aW4gdGlwcyAmIGZpdHVybnlhIiwiXCJIZWxsb1wiIHRvIGJldHRlciB0YWxlbnQgbWF0Y2hlcyI6IlwiSGVsbG9cIiBQZW5jb2Nva2FuIGthbmRpZGF0IGxlYmloIGJhaWsiLCJIaXJlIGZvciBGUkVFIjoiTXVsYWkgc2VrYXJhbmcsIEdSQVRJUyEiLCJBbGwgdGhlIGp1aWN5IHNhbGFyeSBuZXdzIGluIHlvdXIgaW5kdXN0cnkiOiJVcGRhdGUgc2VwdXRhciBpbmZvcm1hc2kgZ2FqaSBkaSBpbmR1c3RyaSBrYW11IiwiR28gdG8gQ29tbXVuaXR5IjoiR2FidW5nIHNla2FyYW5nIn0%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJcIkhlbGxvXCIgdG8gYSBiZXR0ZXIgY2FyZWVyIGFuZCBzYWxhcnkiOiJcIkhlbGxvXCIg4Lie4Lia4LiB4Lix4Lia4LiH4Liy4LiZ4LmB4Lil4Liw4LmA4LiH4Li04LiZ4LmA4LiU4Li34Lit4LiZ4LiX4Li14LmI4LiU4Li14LiB4Lin4LmI4LiyIiwiR2V0IHRpcHMgYW5kIHRvb2xzIjoi4LiV4Lix4Lin4LiK4LmI4Lin4Lii4LmD4LiZ4LiB4Liy4Lij4Lir4Liy4LiH4Liy4LiZIOC4hOC4peC4tOC4gSIsIlwiSGVsbG9cIiB0byBiZXR0ZXIgdGFsZW50IG1hdGNoZXMiOiJcIkhlbGxvXCIg4LiE4LmJ4LiZ4Lir4Liy4LiE4LiZ4LiX4Li14LmI4LmD4LiK4LmI4LmE4LiU4LmJ4LiU4Li14LiB4Lin4LmI4Liy4LiX4Li14LmI4LmA4LiE4LiiIiwiSGlyZSBmb3IgRlJFRSI6IuC4q%2BC4suC4hOC4mSDguJ%2FguKPguLUhIiwiQWxsIHRoZSBqdWljeSBzYWxhcnkgbmV3cyBpbiB5b3VyIGluZHVzdHJ5IjoiQWxsIHRoZSBqdWljeSBzYWxhcnkgbmV3cyBpbiB5b3VyIGluZHVzdHJ5IiwiR28gdG8gQ29tbXVuaXR5IjoiR28gdG8gQ29tbXVuaXR5In0%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJcIkhlbGxvXCIgdG8gYSBiZXR0ZXIgY2FyZWVyIGFuZCBzYWxhcnkiOiJcIkhlbGxvXCIg4Lie4Lia4LiB4Lix4Lia4LiH4Liy4LiZ4LmB4Lil4Liw4LmA4LiH4Li04LiZ4LmA4LiU4Li34Lit4LiZ4LiX4Li14LmI4LiU4Li14LiB4Lin4LmI4LiyIiwiR2V0IHRpcHMgYW5kIHRvb2xzIjoi4LiV4Lix4Lin4LiK4LmI4Lin4Lii4LmD4LiZ4LiB4Liy4Lij4Lir4Liy4LiH4Liy4LiZIOC4hOC4peC4tOC4gSIsIlwiSGVsbG9cIiB0byBiZXR0ZXIgdGFsZW50IG1hdGNoZXMiOiJcIkhlbGxvXCIg4LiE4LmJ4LiZ4Lir4Liy4LiE4LiZ4LiX4Li14LmI4LmD4LiK4LmI4LmE4LiU4LmJ4LiU4Li14LiB4Lin4LmI4Liy4LiX4Li14LmI4LmA4LiE4LiiIiwiSGlyZSBmb3IgRlJFRSI6IuC4q%2BC4suC4hOC4mSDguJ%2FguKPguLUhIiwiQWxsIHRoZSBqdWljeSBzYWxhcnkgbmV3cyBpbiB5b3VyIGluZHVzdHJ5IjoiQWxsIHRoZSBqdWljeSBzYWxhcnkgbmV3cyBpbiB5b3VyIGluZHVzdHJ5IiwiR28gdG8gQ29tbXVuaXR5IjoiR28gdG8gQ29tbXVuaXR5In0%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJcIkhlbGxvXCIgdG8gYSBiZXR0ZXIgY2FyZWVyIGFuZCBzYWxhcnkiOiJcIkhlbGxvXCIga2FyaXIgZGFuIGdhamkgbGViaWggYmFpayIsIkdldCB0aXBzIGFuZCB0b29scyI6IkRhcGV0aW4gdGlwcyAmIGZpdHVybnlhIiwiXCJIZWxsb1wiIHRvIGJldHRlciB0YWxlbnQgbWF0Y2hlcyI6IlwiSGVsbG9cIiBQZW5jb2Nva2FuIGthbmRpZGF0IGxlYmloIGJhaWsiLCJIaXJlIGZvciBGUkVFIjoiTXVsYWkgc2VrYXJhbmcsIEdSQVRJUyEiLCJBbGwgdGhlIGp1aWN5IHNhbGFyeSBuZXdzIGluIHlvdXIgaW5kdXN0cnkiOiJVcGRhdGUgc2VwdXRhciBpbmZvcm1hc2kgZ2FqaSBkaSBpbmR1c3RyaSBrYW11IiwiR28gdG8gQ29tbXVuaXR5IjoiR2FidW5nIHNla2FyYW5nIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJcIkhlbGxvXCIgdG8gYSBiZXR0ZXIgY2FyZWVyIGFuZCBzYWxhcnkiOiJcIkhlbGxvXCIga2FyaXIgZGFuIGdhamkgbGViaWggYmFpayIsIkdldCB0aXBzIGFuZCB0b29scyI6IkRhcGV0aW4gdGlwcyAmIGZpdHVybnlhIiwiXCJIZWxsb1wiIHRvIGJldHRlciB0YWxlbnQgbWF0Y2hlcyI6IlwiSGVsbG9cIiBQZW5jb2Nva2FuIGthbmRpZGF0IGxlYmloIGJhaWsiLCJIaXJlIGZvciBGUkVFIjoiTXVsYWkgc2VrYXJhbmcsIEdSQVRJUyEiLCJBbGwgdGhlIGp1aWN5IHNhbGFyeSBuZXdzIGluIHlvdXIgaW5kdXN0cnkiOiJVcGRhdGUgc2VwdXRhciBpbmZvcm1hc2kgZ2FqaSBkaSBpbmR1c3RyaSBrYW11IiwiR28gdG8gQ29tbXVuaXR5IjoiR2FidW5nIHNla2FyYW5nIn0%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJcIkhlbGxvXCIgdG8gYSBiZXR0ZXIgY2FyZWVyIGFuZCBzYWxhcnkiOiJcIkhlbGxvXCIg4Lie4Lia4LiB4Lix4Lia4LiH4Liy4LiZ4LmB4Lil4Liw4LmA4LiH4Li04LiZ4LmA4LiU4Li34Lit4LiZ4LiX4Li14LmI4LiU4Li14LiB4Lin4LmI4LiyIiwiR2V0IHRpcHMgYW5kIHRvb2xzIjoi4LiV4Lix4Lin4LiK4LmI4Lin4Lii4LmD4LiZ4LiB4Liy4Lij4Lir4Liy4LiH4Liy4LiZIOC4hOC4peC4tOC4gSIsIlwiSGVsbG9cIiB0byBiZXR0ZXIgdGFsZW50IG1hdGNoZXMiOiJcIkhlbGxvXCIg4LiE4LmJ4LiZ4Lir4Liy4LiE4LiZ4LiX4Li14LmI4LmD4LiK4LmI4LmE4LiU4LmJ4LiU4Li14LiB4Lin4LmI4Liy4LiX4Li14LmI4LmA4LiE4LiiIiwiSGlyZSBmb3IgRlJFRSI6IuC4q%2BC4suC4hOC4mSDguJ%2FguKPguLUhIiwiQWxsIHRoZSBqdWljeSBzYWxhcnkgbmV3cyBpbiB5b3VyIGluZHVzdHJ5IjoiQWxsIHRoZSBqdWljeSBzYWxhcnkgbmV3cyBpbiB5b3VyIGluZHVzdHJ5IiwiR28gdG8gQ29tbXVuaXR5IjoiR28gdG8gQ29tbXVuaXR5In0%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJcIkhlbGxvXCIgdG8gYSBiZXR0ZXIgY2FyZWVyIGFuZCBzYWxhcnkiOiJcIkhlbGxvXCIg4Lie4Lia4LiB4Lix4Lia4LiH4Liy4LiZ4LmB4Lil4Liw4LmA4LiH4Li04LiZ4LmA4LiU4Li34Lit4LiZ4LiX4Li14LmI4LiU4Li14LiB4Lin4LmI4LiyIiwiR2V0IHRpcHMgYW5kIHRvb2xzIjoi4LiV4Lix4Lin4LiK4LmI4Lin4Lii4LmD4LiZ4LiB4Liy4Lij4Lir4Liy4LiH4Liy4LiZIOC4hOC4peC4tOC4gSIsIlwiSGVsbG9cIiB0byBiZXR0ZXIgdGFsZW50IG1hdGNoZXMiOiJcIkhlbGxvXCIg4LiE4LmJ4LiZ4Lir4Liy4LiE4LiZ4LiX4Li14LmI4LmD4LiK4LmI4LmE4LiU4LmJ4LiU4Li14LiB4Lin4LmI4Liy4LiX4Li14LmI4LmA4LiE4LiiIiwiSGlyZSBmb3IgRlJFRSI6IuC4q%2BC4suC4hOC4mSDguJ%2FguKPguLUhIiwiQWxsIHRoZSBqdWljeSBzYWxhcnkgbmV3cyBpbiB5b3VyIGluZHVzdHJ5IjoiQWxsIHRoZSBqdWljeSBzYWxhcnkgbmV3cyBpbiB5b3VyIGluZHVzdHJ5IiwiR28gdG8gQ29tbXVuaXR5IjoiR28gdG8gQ29tbXVuaXR5In0%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJcIkhlbGxvXCIgdG8gYSBiZXR0ZXIgY2FyZWVyIGFuZCBzYWxhcnkiOiJbXCLhuKjhur3hur3hur3Gmsaaw7bDtsO2XCIg4bmvw7bDtsO2IMSDxIPEgyDDn%2BG6veG6veG6veG5r%2BG5r%2BG6veG6veG6vcWZIMOnxIPEg8SDxZnhur3hur3hur3hur3hur3hur3FmSDEg8SDxIPguIHguLXguYnGjCDFocSDxIPEg8aaxIPEg8SDxZnDvcO9w71dIiwiR2V0IHRpcHMgYW5kIHRvb2xzIjoiW8em4bq94bq94bq94bmvIOG5r8Osw6zDrMalxaEgxIPEg8SD4LiB4Li14LmJxowg4bmvw7bDtsO2w7bDtsO2xprFoV0iLCJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIjoiW1wi4bio4bq94bq94bq9xprGmsO2w7bDtlwiIOG5r8O2w7bDtiDDn%2BG6veG6veG6veG5r%2BG5r%2BG6veG6veG6vcWZIOG5r8SDxIPEg8aa4bq94bq94bq94LiB4Li14LmJ4bmvIG3MgsSDxIPEg%2BG5r8On4bip4bq94bq94bq9xaFdIiwiSGlyZSBmb3IgRlJFRSI6IlvhuKjDrMOsw6zFmeG6veG6veG6vSDGksO2w7bDtsWZIMaRxZjDi8OLXSIsIkFsbCB0aGUganVpY3kgc2FsYXJ5IG5ld3MgaW4geW91ciBpbmR1c3RyeSI6IlvhuqzGmsaaIOG5r%2BG4qeG6veG6veG6vSDEtceax5rHmsOsw6zDrMOnw73DvcO9IMWhxIPEg8SDxprEg8SDxIPFmcO9w73DvSDguIHguLXguYnhur3hur3hur3FtcWhIMOsw6zDrOC4geC4teC5iSDDvcO9w73DtsO2w7bHmseax5rFmSDDrMOsw6zguIHguLXguYnGjMeax5rHmsWh4bmvxZnDvcO9w71dIiwiR28gdG8gQ29tbXVuaXR5IjoiW8emw7bDtiDhua%2FDtsO2IMOHw7bDtm3Mgm3Mgseax5rguIHguLXguYnDrMOs4bmvw73DvV0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJcIkhlbGxvXCIgdG8gYSBiZXR0ZXIgY2FyZWVyIGFuZCBzYWxhcnkiOiJbXCLhuKjhur3hur3hur3Gmsaaw7bDtsO2XCIg4bmvw7bDtsO2IMSDxIPEgyDDn%2BG6veG6veG6veG5r%2BG5r%2BG6veG6veG6vcWZIMOnxIPEg8SDxZnhur3hur3hur3hur3hur3hur3FmSDEg8SDxIPguIHguLXguYnGjCDFocSDxIPEg8aaxIPEg8SDxZnDvcO9w71dIiwiR2V0IHRpcHMgYW5kIHRvb2xzIjoiW8em4bq94bq94bq94bmvIOG5r8Osw6zDrMalxaEgxIPEg8SD4LiB4Li14LmJxowg4bmvw7bDtsO2w7bDtsO2xprFoV0iLCJcIkhlbGxvXCIgdG8gYmV0dGVyIHRhbGVudCBtYXRjaGVzIjoiW1wi4bio4bq94bq94bq9xprGmsO2w7bDtlwiIOG5r8O2w7bDtiDDn%2BG6veG6veG6veG5r%2BG5r%2BG6veG6veG6vcWZIOG5r8SDxIPEg8aa4bq94bq94bq94LiB4Li14LmJ4bmvIG3MgsSDxIPEg%2BG5r8On4bip4bq94bq94bq9xaFdIiwiSGlyZSBmb3IgRlJFRSI6IlvhuKjDrMOsw6zFmeG6veG6veG6vSDGksO2w7bDtsWZIMaRxZjDi8OLXSIsIkFsbCB0aGUganVpY3kgc2FsYXJ5IG5ld3MgaW4geW91ciBpbmR1c3RyeSI6IlvhuqzGmsaaIOG5r%2BG4qeG6veG6veG6vSDEtceax5rHmsOsw6zDrMOnw73DvcO9IMWhxIPEg8SDxprEg8SDxIPFmcO9w73DvSDguIHguLXguYnhur3hur3hur3FtcWhIMOsw6zDrOC4geC4teC5iSDDvcO9w73DtsO2w7bHmseax5rFmSDDrMOsw6zguIHguLXguYnGjMeax5rHmsWh4bmvxZnDvcO9w71dIiwiR28gdG8gQ29tbXVuaXR5IjoiW8emw7bDtiDhua%2FDtsO2IMOHw7bDtm3Mgm3Mgseax5rguIHguLXguYnDrMOs4bmvw73DvV0ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    