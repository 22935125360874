import { Container, Heading } from '@seek/ai-elements';
import { useTranslations } from '@vocab/react';
import { Badge, Box, IconAI, Inline, Stack } from 'braid-design-system';
import { useEffect, useMemo, useRef, type ComponentProps } from 'react';

import SkeletonLine from 'src/components/SkeletonLine/SkeletonLine';
import { sendHotJarEvent } from 'src/hooks/useHotjarEvent';

import { RECOMMENDED_JOBS_SPACE } from '../DashboardRecommendations/Recommendations';
import { useSignedInDashboardContext } from '../SignedInDashboardContextProvider';

import translations from './.vocab';
import { CareerFeedAIAssistantContent } from './CareerFeedAIAssistantContent/CareerFeedAIAssistantContent';
import { useCareerFeedAIAssistant } from './hooks/useCareerFeedAIAssistant';

const useHeading = () => {
  const { progress } = useCareerFeedAIAssistant();
  const { t } = useTranslations(translations);

  return useMemo(() => {
    switch (progress) {
      case 'initial':
        return t('Get better job matches');
      case 'gatheringRefinement':
      case 'collectGeneralRequirementsAtLast':
        return t('Define your preferences');
      case 'recsRefined':
      case 'recsRefined-withPersistedData':
        return t('Refined for you');
    }
  }, [progress, t]);
};

const CareerFeedBetaBadge = () => {
  const { t } = useTranslations(translations);
  return <Badge tone="neutral">{t('Beta')}</Badge>;
};

const CareerFeedAIAssistantContainer = ({
  showHeading,
  children,
}: {
  showHeading?: boolean;
  children: ComponentProps<typeof Stack>['children'];
}) => {
  const headingText = useHeading();

  return (
    <Box display="flex" flexDirection="column" width="full">
      <Container borderWidth="standard" padding="medium">
        <Stack space="medium">
          {showHeading && (
            <Inline space="xsmall" alignY="center">
              <Heading level="4" icon={<IconAI />}>
                {headingText}
              </Heading>
              <CareerFeedBetaBadge />
            </Inline>
          )}
          {children}
        </Stack>
      </Container>
    </Box>
  );
};

/** *
 * This component is responsible for displaying the AI Assistant for the Career Feed.
 * This is still in in discovery phase and the implementation of this component might change drastically.
 * The reason why this is in the code base is so that people can start playing around with it
 * and get feedback on the implementation.
 */
export const CareerFeedAIAssistant = () => {
  const {
    recommendationsResult: {
      error: isRecommendationError,
      loading: isRecommendationLoading,
      isRefreshLoading,
      rawRecommendedJobs,
    },
  } = useSignedInDashboardContext();
  const { persistedCandidateChatContext, progress } =
    useCareerFeedAIAssistant();
  const hotjarGenAIEventSent = useRef(false);
  const jobsCount = rawRecommendedJobs?.globalJobs.length ?? 0;
  const isLoadingState =
    (isRecommendationLoading && !isRefreshLoading) ||
    (progress === 'initial' && isRefreshLoading);

  useEffect(() => {
    if (
      !isRecommendationError &&
      !isLoadingState &&
      jobsCount > 0 &&
      !hotjarGenAIEventSent.current
    ) {
      sendHotJarEvent('homepage_genAI');
      hotjarGenAIEventSent.current = true;
    }
  }, [isLoadingState, isRecommendationError, jobsCount]);

  if (isRecommendationError) {
    return null;
  }

  if (!persistedCandidateChatContext && !isLoadingState && jobsCount === 0) {
    return null;
  }

  return (
    <Stack space={RECOMMENDED_JOBS_SPACE}>
      <Box userSelect="none" display={{ mobile: 'none', desktop: 'block' }}>
        <Heading level="3">&nbsp;</Heading>
      </Box>
      <CareerFeedAIAssistantContainer showHeading={!isLoadingState}>
        {isLoadingState ? (
          <Stack space="medium">
            <SkeletonLine type="text" width={200} size="small" />
            <SkeletonLine type="text" width={260} size="xsmall" />
          </Stack>
        ) : (
          <CareerFeedAIAssistantContent />
        )}
      </CareerFeedAIAssistantContainer>
    </Stack>
  );
};
