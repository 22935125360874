import { useTranslations } from '@vocab/react';
import { Box, Divider, Stack, Text } from 'braid-design-system';
import { useMemo, type ComponentProps } from 'react';

import { GenAIChatLoadingOverlay } from '../LoadingOverlay';
import type { ErrorType } from '../types';

import translations from './.vocab';
import { TextSection } from './TextSection';

const ErrorMessage = ({ errorType }: { errorType: ErrorType }) => {
  const { t } = useTranslations(translations);

  const errorText = useMemo(() => {
    if (errorType === 'INPUT_OR_OUTPUT_BLOCKED') {
      return t('I can only help you with your job search.');
    } else if (errorType === 'TOO_MANY_REQUESTS') {
      return t('You’ve done this too many times today. Try again later.');
    }

    return t('Can we try that again? We’re having trouble connecting.');
  }, [errorType, t]);

  return <Text tone="critical">{errorText}</Text>;
};

export const CareerFeedAIAssistantContentFrame = ({
  headMessage,
  errorType,
  contentSection,
  ctaSection,
  loading,
}: {
  headMessage:
    | {
        size?: ComponentProps<typeof TextSection>['size'];
        text: ComponentProps<typeof TextSection>['children'];
      }
    | string;
  contentSection?: ComponentProps<typeof Stack>['children'];
  ctaSection: ComponentProps<typeof Stack>['children'];
  errorType?: ComponentProps<typeof ErrorMessage>['errorType'] | null;
  loading: boolean;
}) => (
  <Stack space="medium">
    <GenAIChatLoadingOverlay isGenerating={loading}>
      <Box paddingY="xxsmall">
        <Stack space="medium">
          {typeof headMessage === 'string' ? (
            <TextSection>{headMessage}</TextSection>
          ) : (
            <TextSection size={headMessage.size}>
              {headMessage.text}
            </TextSection>
          )}
          {contentSection}
          {errorType ? <ErrorMessage errorType={errorType} /> : null}
          <Divider />
        </Stack>
      </Box>
    </GenAIChatLoadingOverlay>
    {ctaSection}
  </Stack>
);
