import { useTranslations } from '@vocab/react';
import {
  Button,
  ButtonIcon,
  Column,
  Columns,
  IconArrow,
  Inline,
  Textarea,
  Actions,
} from 'braid-design-system';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { CareerFeedAIAssistantContentFrame } from '../CareerFeedAIAssistantContent/CareerFeedAIAssistantContentFrame';
import { useCareerFeedAIAssistant } from '../hooks/useCareerFeedAIAssistant';
import type { ErrorType } from '../types';

import translations from './.vocab';
import { FeedbackComponent } from './FeedbackComponent';
import { OptionsButtons } from './OptionsButtons';
import { useSuggestions } from './useSuggestions';

export type CareerFeedAssistantQuestionSuggestion = {
  label: string;
  nextPrompt: string;
  showFreeTextField?: boolean;
  isSkipButton?: boolean;
};

type CareerFeedFreeTextInputProps = {
  onSendMessage: (message: string) => void;
  isLoading: boolean;
  changeKeys?: readonly unknown[];
};

type CareerFeedAssistantQuestionProps = {
  message: string;
  suggestions: CareerFeedAssistantQuestionSuggestion[];
  isLoading: boolean;
  onSendMessage: (
    userFreeTextMessage: string,
    skip: boolean,
    selectedSuggestions: CareerFeedAssistantQuestionSuggestion[],
  ) => void;
  onSendFeedback: (sentiment: boolean) => void;
  showFreeTextFieldOnly?: boolean;
  sendMessageError?: ErrorType | null;
};

const CHARACTER_LIMIT_FOR_FREE_TEXT = 280;

const CareerFeedFreeTextInput = ({
  onSendMessage,
  isLoading,
  changeKeys = [],
}: CareerFeedFreeTextInputProps) => {
  const { t } = useTranslations(translations);
  const [inputField, setInputField] = useState('');

  useEffect(() => {
    setInputField('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, changeKeys);

  const onSendButtonClick = useCallback(() => {
    if (
      inputField.length > 0 &&
      !isLoading &&
      inputField.length <= CHARACTER_LIMIT_FOR_FREE_TEXT
    ) {
      onSendMessage(inputField);
    }
  }, [inputField, isLoading, onSendMessage]);

  return (
    <Columns space="small" alignY="center">
      <Column>
        <Textarea
          aria-label={t('message')}
          id="career-feed-free-text-area"
          value={inputField}
          onChange={(event) => setInputField(event.currentTarget.value)}
          characterLimit={CHARACTER_LIMIT_FOR_FREE_TEXT}
          disabled={isLoading}
        />
      </Column>
      <Column width="content">
        <ButtonIcon
          icon={<IconArrow direction="up" />}
          label={t('Send')}
          id="send-message"
          onClick={onSendButtonClick}
        />
      </Column>
    </Columns>
  );
};

const CareerFeedAssistantQuestion = ({
  message,
  suggestions: _suggestions,
  isLoading,
  onSendMessage,
  onSendFeedback,
  showFreeTextFieldOnly,
  sendMessageError,
}: CareerFeedAssistantQuestionProps) => {
  const { t } = useTranslations(translations);

  const { suggestions, selectedSuggestions } = useSuggestions(_suggestions);

  const showFreeTextField = useMemo(
    () =>
      selectedSuggestions.find((suggestion) => suggestion.showFreeTextField),
    [selectedSuggestions],
  );

  const [selectedPositiveFeedback, setSelectedPositiveFeedback] =
    useState(false);
  const [selectedNegativeFeedback, setSelectedNegativeFeedback] =
    useState(false);

  const [sentFeedback, setSentFeedback] = useState(false);

  useEffect(() => {
    setSelectedPositiveFeedback(false);
    setSelectedNegativeFeedback(false);
    setSentFeedback(false);
  }, [message]);

  const onNextButtonClick = useCallback(() => {
    if (selectedSuggestions.length) {
      const hasSkipButton = Boolean(
        selectedSuggestions.find(({ isSkipButton }) => isSkipButton),
      );

      onSendMessage('', hasSkipButton, selectedSuggestions);
    }
  }, [selectedSuggestions, onSendMessage]);

  const onSendFreeTextMessage = useCallback(
    (freeTextMessage: string) => {
      onSendMessage(freeTextMessage, false, selectedSuggestions);
    },
    [onSendMessage, selectedSuggestions],
  );

  const onSkipButtonClick = useCallback(() => {
    onSendMessage('', true, selectedSuggestions);
  }, [onSendMessage, selectedSuggestions]);

  return (
    <CareerFeedAIAssistantContentFrame
      headMessage={message}
      contentSection={
        <>
          {showFreeTextFieldOnly ? (
            <Actions>
              <Button
                size="small"
                onClick={onSkipButtonClick}
                variant="ghost"
                loading={isLoading}
              >
                {t('Skip')}
              </Button>
            </Actions>
          ) : null}
          {suggestions.length ? (
            <Inline space="xsmall">
              <OptionsButtons
                options={suggestions}
                disableOnClick={isLoading}
              />
            </Inline>
          ) : null}
          {!showFreeTextFieldOnly && (
            <FeedbackComponent
              positiveFeedbackAction={() => {
                onSendFeedback(true);
                setSelectedPositiveFeedback(true);
                setSentFeedback(true);
              }}
              negativeFeedbackAction={() => {
                onSendFeedback(false);
                setSelectedNegativeFeedback(true);
                setSentFeedback(true);
              }}
              selectedPositiveFeedback={selectedPositiveFeedback}
              selectedNegativeFeedback={selectedNegativeFeedback}
              sentFeedback={sentFeedback}
            />
          )}
        </>
      }
      errorType={sendMessageError}
      ctaSection={
        showFreeTextFieldOnly || showFreeTextField ? (
          <CareerFeedFreeTextInput
            onSendMessage={onSendFreeTextMessage}
            isLoading={isLoading}
            changeKeys={[showFreeTextFieldOnly, showFreeTextField]}
          />
        ) : (
          <Button
            tone="brandAccent"
            variant="soft"
            loading={isLoading}
            onClick={onNextButtonClick}
          >
            {t('Next')}
          </Button>
        )
      }
      loading={isLoading}
    />
  );
};

export const CareerFeedAssistantQuestions = () => {
  const {
    invokeSendMessage,
    invokeSendFeedback,
    sendMessageError,
    lastCompletedAssistantResponse,
    isAssistantResponsePending,
    tracking: {
      trackGenAIChatOutputDisplayed,
      trackGenAIChatInputPressed: trackGenAIChatInputSubmitPressed,
      trackGenAIChatInputSucceeded: trackGenAIChatInputSubmitted,
      trackGenAIChatFeedbackPressed,
    },
  } = useCareerFeedAIAssistant();

  const onSendMessage = useCallback(
    async (
      message: string,
      skip: boolean,
      selectedSuggestions: CareerFeedAssistantQuestionSuggestion[],
    ) => {
      const submitPressedPayload = trackGenAIChatInputSubmitPressed(
        message,
        selectedSuggestions.map(({ label, showFreeTextField }) => ({
          label,
          isFreeTextInput: Boolean(showFreeTextField),
        })),
      );

      const prompt = selectedSuggestions
        .map(({ nextPrompt }) => nextPrompt)
        .concat(message)
        .filter((userPrompt) => userPrompt && userPrompt.length !== 0)
        .join('. ');

      const error = await invokeSendMessage(prompt, skip);

      if (!error) {
        if (submitPressedPayload) {
          trackGenAIChatInputSubmitted(submitPressedPayload);
        }
      } else {
        trackGenAIChatOutputDisplayed(error);
      }
    },
    [
      invokeSendMessage,
      trackGenAIChatInputSubmitPressed,
      trackGenAIChatInputSubmitted,
      trackGenAIChatOutputDisplayed,
    ],
  );

  const onSendFeedback = useCallback(
    async (sentiment: boolean) => {
      trackGenAIChatFeedbackPressed(sentiment);
      invokeSendFeedback(sentiment);
    },
    [invokeSendFeedback, trackGenAIChatFeedbackPressed],
  );

  if (
    !lastCompletedAssistantResponse ||
    lastCompletedAssistantResponse.role !== 'assistant' ||
    lastCompletedAssistantResponse.loading
  ) {
    return null;
  }

  return (
    <CareerFeedAssistantQuestion
      message={lastCompletedAssistantResponse.text}
      suggestions={lastCompletedAssistantResponse.suggestions ?? []}
      isLoading={isAssistantResponsePending}
      onSendMessage={onSendMessage}
      showFreeTextFieldOnly={
        lastCompletedAssistantResponse.collectGeneralRequirements
      }
      sendMessageError={sendMessageError}
      onSendFeedback={onSendFeedback}
    />
  );
};
