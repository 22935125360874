import { useTranslations } from '@vocab/react';
import { Box, Hidden, HiddenVisually, Stack } from 'braid-design-system';
import { memo } from 'react';

import { useHotjarEvent } from 'src/hooks/useHotjarEvent';
import {
  useIsHomepageGenAIChatAltModelEnabled,
  useIsHomepageGenAIChatEnabled,
  useSavedAndRecentSearchesFeatureFlag,
} from 'src/store/featureFlags/hooks';

import translations from './.vocab';
import { CareerFeedAIAssistant } from './CareerFeedAIAssistant/CareerFeedAIAssistant';
import { CareerFeedAIAssistantProvider } from './CareerFeedAIAssistant/hooks/useCareerFeedAIAssistant';
import { Recommendations } from './DashboardRecommendations/Recommendations';
import SavedSearches from './DashboardSaveSearches/SavedSearches';
import SavedJobs from './DashboardSavedJobs/SavedJobs';
import { MyActivityTiles } from './MyActivityTiles/MyActivityTiles';
import { JobDetailsViewDrawer } from './SharedComponent/JobDetailsViewDrawer/JobDetailsViewDrawer';
import { SignedInDashboardContextProvider } from './SignedInDashboardContextProvider';

import * as styleRefs from './SignedInDashboard.css';

const SignedInDashboardView = () => {
  const { t } = useTranslations(translations);
  const isHomepageGenAIChatEnabled = useIsHomepageGenAIChatEnabled();

  useHotjarEvent('homepage_layout_refresh_experiment');
  const isSavedAndRecentSearches = useSavedAndRecentSearchesFeatureFlag();

  return (
    <>
      <HiddenVisually>
        <h1>{t('Dashboard')}</h1>
      </HiddenVisually>
      <Box
        display="flex"
        flexDirection={{ mobile: 'columnReverse', desktop: 'row' }}
        className={styleRefs.columnSpace}
      >
        <Box flexGrow={1}>
          <Recommendations />
        </Box>
        <Box className={styleRefs.width40percent}>
          <Stack space={{ mobile: 'medium', desktop: 'large' }}>
            {!isSavedAndRecentSearches && (
              <Hidden above="tablet">
                <MyActivityTiles />
              </Hidden>
            )}
            {isHomepageGenAIChatEnabled ? <CareerFeedAIAssistant /> : null}
            {!isSavedAndRecentSearches && (
              <Hidden below="desktop">
                <SavedSearches />
              </Hidden>
            )}
            <Hidden below="desktop">
              <SavedJobs />
            </Hidden>
          </Stack>
        </Box>
      </Box>
      <JobDetailsViewDrawer />
    </>
  );
};

const SignedInDashboard = () => {
  const isHomepageGenAIChatAltModelEnabled =
    useIsHomepageGenAIChatAltModelEnabled();

  return (
    <CareerFeedAIAssistantProvider
      useAltModel={isHomepageGenAIChatAltModelEnabled}
    >
      <SignedInDashboardContextProvider>
        <SignedInDashboardView />
      </SignedInDashboardContextProvider>
    </CareerFeedAIAssistantProvider>
  );
};

export default memo(SignedInDashboard);
